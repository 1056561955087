import React from 'react';
/*import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
//import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, Divider, Typography } from '@mui/material';*/
//import functions from '../../functions/functions';
import api from '../../apis/api';
import fastfield from '../../apis/fastfield';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../styles';
import { Typography, CircularProgress } from '@mui/material';
//import testImage from '../../../src/images/screenshots_helpers/airDroopEx2.JPG';

class FastFieldImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentFile: props.file,
      //src: testImage,
    };
  }
  async componentDidMount() {
    this.getFastFieldImage();
  }

  async componentDidUpdate() {
    this.getFastFieldImage();
  }
  //! TODO NEED TO CHECK IF I HAVE THE IMAGE URL ALREADY and try and use that instead?
  getFastFieldImage = async () => {
    const auth = await fastfield.getFastFieldAuth();
    if (auth === '' || !auth) {
      //! TODO
      console.log('THROW NO AUTH ERROR');
    } else if (!this.props.file) {
      //! TODO
      console.log('THROW NO FILE REQUESTED ERROR');
    } else if (!this.state.src || this.state.currentFile !== this.props.file) {
      //allow to swap source
      const authUrl = 'iba/getMedia';
      const file = this.props.file;
      const response = await api.post(authUrl, {
        token: auth,
        file,
      });
      //console.log(response);
      this.setState({ src: response.data, currentFile: this.props.file });
    }
  };
  render() {
    const { classes } = this.props;
    if (!this.props.file) {
      return (
        <Typography onClick={(e) => e.stopPropagation()} color='error'>
          Error: No Image Defined!
        </Typography>
      );
    } else if (!this.state.src) {
      return (
        <Typography onClick={(e) => e.stopPropagation()} color='error'>
          Error: No Image Source Defined!
        </Typography>
      );
    } else {
      return (
        <React.Fragment>
          {!this.state.src ? (
            <Typography style={{ padding: 16 }}>
              <CircularProgress className={classes.loadIcon} size={15} color='secondary' />
              Loading...
            </Typography>
          ) : (
            <img
              alt={!this.props.alt ? 'Fastfield Image' : this.props.alt}
              src={this.state.src}
              width={this.props.width}
              height={this.props.height}
              object-fit={'scale-down'}
              className={this.props.thumbnail ? classes.image : classes.imageExpanded}
            />
          )}
        </React.Fragment>
      );
    }
    //}
  }
}

export default withStyles(styles)(FastFieldImage);
