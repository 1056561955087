import _ from 'lodash';
import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../styles.js';
import { connect } from 'react-redux';
//import { Link } from '@mui/icons-material';
import { /*Checkbox,*/ Typography, /*ListItem, Tooltip, /*TablePagination, */ Button /*, Paper*/ } from '@mui/material';
import FSRTile from '../tiles/FSRTile.js';
import CustomSelect from '../filters/CustomSelect.js';
import { RestartAlt } from '@mui/icons-material';
import Grid from '@mui/material/Grid';
import functions from '../../functions/functions.js';
import {
  fetchOptimizeDevices,
  fetchDevicesSummary,
  fetchDevices,
  fetchEvents,
  setTableOffset,
  setTablePageSize,
  setTablePage,
  setModule,
  updateSort,
  updateDateRange,
  fetchLocations,
  fetchApplications,
  fetchImportances,
  fetchSites,
  fetchIssueTypes,
  deleteLoops,
  deleteDevices,
  fetchSort,
  fetchLoops,
  updateFilter,
  clearFilter,
} from '../../actions/index';

import { CircularProgress } from '@mui/material';
import WidgetTitle from '../headers/WidgetTitle.js';
import ReportChartWidget from '../widgets/ReportChartWidget.js';
//import Highcharts from 'highcharts';
import initializeTheme from '../theme.js';
//import TableWidget from '../widgets/TableWidget.js';
//import fastfield from '../../apis/fastfield.js';
//import api from '../../apis/api.js';
//import FastFieldImage from '../../components/test/FastFieldImage.js';
//import zapier from '../../functions/zapierJavascriptTest.js';

class FSR extends React.Component {
  constructor(props) {
    super(props);
    this.state = { filter: {} };
    //this.processData = this.processData.bind(this);
  }
  componentDidMount() {
    if (this.state.currentModule !== 'FSR') {
      this.props.setModule('FSR');
    }
    this.props.fetchDevices(this.props.match.params.id);
    //this.props.fetchDevicesSummary();
    //console.log(zapier());
    /*console.log(JSON.parse(`{"display": "lat 40.616870, lng -74.036145", "horizontal_accuracy": 12.754, "latitude": 40.61687, "longitude": -74.036145, "vertical_accuracy": -1}`));*/
    /*console.log(
      JSON.parse(
        `[{"comment": "None", "photo": "109305_6ab2ec2a-77c7-47e5-9b63-39738289728b_3eb50803-4c94-47e8-80b2-aa5a4f0f57c9.jpeg"}, {"comment": "None", "photo": "109305_6ab2ec2a-77c7-47e5-9b63-39738289728b_25505b56-48e0-414c-85d1-42cf051dafbc.jpeg"}]`
      )
    );*/
    // this.props.fetchOptimizeDevices(0, 24);
  }

  renderLoading() {
    const { classes } = this.props;
    if (this.state.loading) {
      return (
        <div>
          <Typography variant='subtitle2' style={{ marginTop: 12 }}>
            <CircularProgress className={classes.loadIcon} size={15} color='secondary' />
            Loading...
          </Typography>
        </div>
      );
    } else {
      return;
    }
  }

  resetFilters = async () => {
    this.setState({ changes: true });
    await this.props.clearFilter();
    this.props.updateSort('Importance');
    this.multiSelectOnClose();
  };
  handleSort = async (event, value, type) => {
    return 'fix';
    // this.props.updateSort(value);
    // // TODO FINISH THIS WHEN TABLE FULLY IN REDUX
    // let filters = {
    //   Application: this.props.sort.filter.Application,
    //   Site: this.props.sort.filter.Site,
    //   Location: this.props.sort.filter.Location,
    //   Disruption: this.props.sort.filter.Disruption,
    //   startDate: this.props.sort.filter.Range.startDate,
    //   endDate: this.props.sort.filter.Range.endDate,
    //   analysisRange: this.props.sort.filter.analysisRange,
    //   Devicetypes: this.props.sort.filter['Device Types'],
    //   Issuetypes: this.props.sort.filter['Issue Types'],
    //   Importance: this.props.sort.filter.Importance,
    //   SpecificDevice: this.props.sort.filter.SpecificDevice,
    //   Concerns: JSON.stringify(this.props.sort.concern),
    // };
    // if (
    //   !(
    //     !filters.Application &&
    //     !filters.Disruption &&
    //     !filters.Devicetypes &&
    //     !filters.Issuetypes &&
    //     !filters.Importance &&
    //     !filters.Location &&
    //     !filters.Site &&
    //     !filters.Concerns &&
    //     this.props.auth.require_filter
    //   )
    // ) {
    //   this.props.deleteDevices();
    //   this.props.deleteLoops();
    //   //TODO Finish with update of page

    //   //console.log(filters);
    //   //console.log(this.props.sort.filter);
    //   this.setState({ loading: true });
    //   this.props.setTablePage(0);
    //   //}

    //   //console.log(filters);

    //   await this.props.fetchLoops(0, this.props.table.pageSize, value, filters, this.props.sort.filter.Dataset).then(() => {
    //     this.setState({
    //       //page,
    //       //rowsPerPage,
    //       loading: false,
    //       changes: false,
    //     });
    //   });
    //   //this.props.fetchLoopsSummary();
    // }
  };
  renderTotals() {
    return 'fix';
    // const { classes } = this.props;
    // let issueKeys = Object.keys(this.props.loops).map((key) => {
    //   return this.props.loops[key].issues.length;
    // });
    // let totalIssues = 0;
    // issueKeys.forEach((issue) => {
    //   totalIssues += issue;
    // });
    // let actionKeys = Object.keys(this.props.loops).map((key) => {
    //   return this.props.loops[key].actions.length;
    // });
    // let totalActions = 0;
    // actionKeys.forEach((action) => {
    //   totalActions += action;
    // });
    // return (
    //   <Grid container className={classes.totalsContainer} spacing={2}>
    //     <Grid item>{<Typography variant='subtitle2'>Total Issues: {!totalIssues ? 0 : totalIssues}</Typography>}</Grid>
    //     <Grid item>{<Typography variant='subtitle2'>Total Action Items: {!totalActions ? 0 : totalActions}</Typography>}</Grid>
    //   </Grid>
    // );
  }

  multiSelectOnClose = async (event, label, changesOverride) => {
    let obj = { ...this.props.sort.filter };
    this.setState({ filter: obj });
  };

  multiSelectOnChange = async (event, value, type) => {
    let obj = { ...this.props.sort.filter };
    let name = !event.target.name ? event.target.id.split('-')[2] : event.target.name;
    obj[name] = value;
    this.props.updateFilter(obj);
  };

  refreshPageView = (offset, rowsPerPage, expectedPage) => {
    return 'fix';
    // let filters = {
    //   Application: this.props.sort.filter.Application,
    //   Site: this.props.sort.filter.Site,
    //   Location: this.props.sort.filter.Location,
    //   Disruption: this.props.sort.filter.Disruption,
    //   startDate: this.props.sort.filter.Range.startDate,
    //   endDate: this.props.sort.filter.Range.endDate,
    //   analysisRange: this.props.sort.filter.analysisRange,
    //   Devicetypes: this.props.sort.filter['Device Types'],
    //   Issuetypes: this.props.sort.filter['Issue Types'],
    //   Importance: this.props.sort.filter.Importance,
    //   SpecificDevice: this.props.sort.filter.SpecificDevice,
    //   Concerns: JSON.stringify(this.props.sort.concern),
    // };
    // if (
    //   !(
    //     !filters.Application &&
    //     !filters.Disruption &&
    //     !filters.Devicetypes &&
    //     !filters.Issuetypes &&
    //     !filters.Importance &&
    //     !filters.Location &&
    //     !filters.Site &&
    //     !filters.Concerns &&
    //     this.props.auth.require_filter
    //   )
    // ) {
    //   //Check to see if we already pulled the row data, if so don't bother the API again
    //   //console.log(this.props.sort);
    //   let rowsExist = true;
    //   const page = Math.floor(offset / rowsPerPage); // correct the page number for changing the page size
    //   //const rowList = this.props.loops.map(loop => loop.rowId);
    //   const rowList = _.map(this.props.loops, (loop) => loop.rowId);
    //   for (let i = offset; i < offset + rowsPerPage; i++) {
    //     if (!rowList.includes(i + 1)) {
    //       rowsExist = false;
    //     }
    //   }
    //   if (rowsExist) {
    //     this.setState({
    //       page,
    //       rowsPerPage,
    //       loading: false,
    //     });
    //   } else {
    //     this.props.fetchLoops(offset, rowsPerPage, this.props.sort.sort, filters, this.props.sort.filter.Dataset).then(() => {
    //       this.setState({
    //         page,
    //         rowsPerPage,
    //         loading: false,
    //       });
    //     });
    //   }
    // }
  };

  handleChangePage = (event, page) => {
    return 'fix';
    // this.setState({
    //   loading: true,
    // });
    // this.props.setTableOffset(this.props.table.pageSize * page);
    // this.props.setTablePage(page);
    // const offset = this.props.table.pageSize * page;
    // const rowsPerPage = this.props.table.pageSize;
    // this.refreshPageView(offset, rowsPerPage, page);
  };
  handleChangeRowsPerPage = (event) => {
    return 'fix';
    // this.setState({
    //   loading: true,
    // });
    // this.props.setTablePageSize(event.target.value);
    // this.props.setTableOffset(this.props.table.page * this.props.table.pageSize);
    // const offset = this.props.table.page * this.props.table.pageSize;
    // const rowsPerPage = event.target.value;
    // const page = Math.floor(offset / rowsPerPage);
    // this.props.setTablePage(page);
    // this.refreshPageView(offset, rowsPerPage, page);
  };

  additionalOlinProcessing = (obj) => {
    const theme = initializeTheme(this.props.colorMode);
    let localObj = obj;
    localObj.status = this.checkDeviceOperatingStatusOlin(localObj);
    let color = localObj.status === 'Issue Found' ? '#922' : localObj.status === 'Turned Away' ? theme.palette.blue.color : '#308D3B';
    localObj.style = { borderLeft: '8px solid ' + color };

    //localObj.follow_up_required = localObj.follow_up_required === '1' ? 'Yes' : 'No';
    localObj.is_follow_up = localObj.is_follow_up === '1' ? 'Yes' : 'No';

    //localObj.follow_up_required = localObj.follow_up_required === '1' ? 'Yes' : 'No';
    localObj.combined_follow_up_required = localObj.follow_up_required === 'Yes' || localObj.repair_required === 'Yes, put WO# in Comments' ? 'Yes' : 'No';
    let signature_date_utc = new Date(localObj.signature_date).getTime();
    localObj.signature_date = functions.dateToString(signature_date_utc, 'date');

    localObj.dcs_measurement_af = parseFloat(localObj.dcs_measurement_af);
    localObj.dcs_measurement_al = parseFloat(localObj.dcs_measurement_al);
    localObj.field_measurement_af = parseFloat(localObj.field_measurement_af);
    localObj.field_measurement_al = parseFloat(localObj.field_measurement_al);

    localObj.maximum_dcs_deviation = parseFloat(localObj.maximum_dcs_deviation);
    localObj.measurement_range_high = parseFloat(localObj.measurement_range_high);
    localObj.measurement_range_low = parseFloat;
    localObj.within_dcs = localObj.within_dcs === 1 ? 'Yes' : localObj.within_dcs === 0 ? 'No' : localObj.within_dcs;

    //localObj.service_type = localObj.deviceId % 2 === 0 ? 'Scheduled' : 'Unscheduled';

    localObj.utcTime = new Date(localObj.date_time).getTime();
    localObj.serviceMonth = ('0' + (new Date(localObj.date_time).getMonth() + 1)).slice(-2) + ' - ' + new Date(localObj.date_time).getFullYear();
    return localObj;
  };

  additionalStreamlineProcessing = (obj) => {
    const theme = initializeTheme(this.props.colorMode);
    let localObj = obj;
    localObj.meterCheck = this.checkDeviceOperatingStatus(localObj);
    //console.log(localObj.meterCheck);
    localObj.actionCheck = 'TRUE';
    localObj.further_repair = localObj.further_repair === '1' ? 'Yes' : 'No';
    localObj.change_found_left_pH = localObj.as_left_value_pH - localObj.as_found_value_pH;
    localObj.as_found_value_rounded_pH = Math.round(localObj.as_found_value_pH * 10) / 10;
    localObj.as_left_value_rounded_pH = Math.round(localObj.as_left_value_pH * 10) / 10;
    localObj.change_value_rounded_pH = Math.round((localObj.as_left_value_pH - localObj.as_found_value_pH) * 10) / 10;

    localObj.change_found_left_orp = localObj.as_left_value_orp - localObj.as_found_value_orp;
    localObj.as_found_value_rounded_orp = Math.round(localObj.as_found_value_orp * 10) / 10;
    localObj.as_left_value_rounded_orp = Math.round(localObj.as_left_value_orp * 10) / 10;
    localObj.change_value_rounded_orp = Math.round((localObj.as_left_value_orp - localObj.as_found_value_orp) * 10) / 10;

    /*localObj.report_link = (
            <Button variant='outlined' onClick={() => report_link_function(localObj.submission_id)}>
              Get Report
            </Button>
          );*/
    /*let lat = localObj.location_gps_lat;
          let lat_suff = lat > 0 ? 'N' : 'S';
          let long = localObj.location_gps_long;
          let long_suff = long > 0 ? 'E' : 'W';
          localObj.map_link = <a href={`https://www.google.com/maps/place/${Math.abs(lat)}${lat_suff}+${Math.abs(long)}${long_suff}`}>Map Link</a>;
          console.log(`https://www.google.com/maps/place/${Math.abs(lat)}${lat_suff}+${Math.abs(long)}${long_suff}`);*/
    localObj.report_link = localObj.deviceId; //<FastFieldImage file={localObj.asset_photos[0]} />;
    localObj.status = this.checkDeviceOperatingStatus(localObj);
    //off, recommended action, no issue
    //TODO Consider alternative coloration
    let color = localObj.status === 'Broken Probe' ? '#922' : localObj.status === 'Additional Action' ? theme.palette.blue.color : '#308D3B';
    //console.log(color);
    localObj.style = { borderLeft: '8px solid ' + color };

    //localObj.service_type = localObj.deviceId % 2 === 0 ? 'Scheduled' : 'Unscheduled';

    localObj.utcTime = new Date(localObj.date_time).getTime();
    localObj.serviceMonth = `${new Date(localObj.date_time).getMonth() + 1} - ${new Date(localObj.date_time).getFullYear()}`;

    //DATA CLEANUP TO NORMAL
    localObj.further_repair = localObj.further_repair === '0' ? 'No' : 'Yes';
    localObj.customer_notified = localObj.customer_notified === '0' ? 'No' : 'Yes';
    localObj.application = localObj.application === 'R' ? 'Regen' : 'Spent';
    return localObj;
  };

  checkDeviceOperatingStatusOlin = (device) => {
    if (!device) {
      return 'Error';
    } else if (!!device.turned_away && device.turned_away === 1) {
      return 'Turned Away';
    } /* else if (device.meter_measurement_check === '0') {
      return 'Incorrect';
    }*/ else if (
      device.is_tag_label_damaged === 'Yes' ||
      device.is_special_function_label_damaged === 'Yes' ||
      device.is_cable_label_damaged === 'Yes' ||
      device.shs_corrosion === 'Yes' ||
      device.shs_connections === 'Yes' ||
      device.shs_indicators_damaged === 'Yes' ||
      device.shs_pressure_flow_values === 'No' ||
      device.shs_functioning === 'No' ||
      device.shs_utilities_functioning === 'No' ||
      device.shs_heat_tracing_damage === 'Yes' ||
      device.loose_wiring === 'Yes' ||
      device.cable_damaged === 'Yes' ||
      device.covers_drains_plugs_missing === 'Yes' ||
      device.conduit_openings === 'Yes' ||
      device.analyzer_damage === 'Yes' ||
      device.indicator_damage === 'Yes' ||
      device.piping_damage === 'Yes' ||
      device.analyzer_heat_tracing_damage === 'Yes' ||
      device.sensor_connection_damage === 'Yes' ||
      device.probe_damage === 'Yes' ||
      device.probe_buildup === 'Yes' ||
      device.transmitter_issue === 'Yes' ||
      device.sap_discrepancy === 'Yes' ||
      //SPACER
      //SPACER

      device.is_tag_label_damaged === 'Quick Fix' ||
      device.is_special_function_label_damaged === 'Quick Fix' ||
      device.is_cable_label_damaged === 'Quick Fix' ||
      device.shs_corrosion === 'Quick Fix' ||
      device.shs_connections === 'Quick Fix' ||
      device.shs_indicators_damaged === 'Quick Fix' ||
      device.shs_pressure_flow_values === 'Quick Fix' ||
      device.shs_functioning === 'Quick Fix' ||
      device.shs_utilities_functioning === 'Quick Fix' ||
      device.shs_heat_tracing_damage === 'Quick Fix' ||
      device.loose_wiring === 'Quick Fix' ||
      device.cable_damaged === 'Quick Fix' ||
      device.covers_drains_plugs_missing === 'Quick Fix' ||
      device.conduit_openings === 'Quick Fix' ||
      device.analyzer_damage === 'Quick Fix' ||
      device.indicator_damage === 'Quick Fix' ||
      device.piping_damage === 'Quick Fix' ||
      device.analyzer_heat_tracing_damage === 'Quick Fix' ||
      device.sensor_connection_damage === 'Quick Fix' ||
      device.probe_damage === 'Quick Fix' ||
      device.probe_buildup === 'Quick Fix' ||
      device.transmitter_issue === 'Quick Fix' ||
      device.sap_discrepancy === 'Quick Fix'
    ) {
      return 'Issue Found';
    } else {
      return 'No Issue';
    }
  };

  checkDeviceOperatingStatus = (device) => {
    if (!device) {
      return 'Error';
    } else if (!!device.probe_broken && device.probe_broken.toLowerCase() !== 'no' && device.probe_broken.toLowerCase() !== '0') {
      return 'Broken Probe';
    } /* else if (device.meter_measurement_check === '0') {
      return 'Incorrect';
    }*/ else if (!!device.comment && device.comment.toLowerCase() !== 'no' && device.comment.toLowerCase() !== 'none') {
      return 'Additional Action';
    } else {
      return 'No Issue';
    }
  };

  render() {
    //TODO ARRANGE FILTERS BELOW TO BE TYPED
    //const { classes } = this.props;
    let fsr_type = !this.props.fsr_type ? 'default' : this.props.fsr_type;
    let device = !this.props ? null : this.props.loops[this.props.match.params.id];
    if (!device) {
      return 'Loading...';
    }
    if (fsr_type === 'Streamline' || fsr_type === 'Demo') {
      device = this.additionalStreamlineProcessing(device);
    }
    if (fsr_type === 'Olin') {
      device = this.additionalOlinProcessing(device);
    }
    //let data = this.processData(fsr_type);
    // device = data[0];
    let index = 1;
    return (
      <React.Fragment>
        <FSRTile type={fsr_type} key={`fsr_tile_${index}`} device={device} hasBorder alwaysExpanded />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    applications: state.applications,
    importances: state.importances,
    locations: state.locations,
    loops: state.devices.devices,
    loopCount: state.devices.count,
    format: state.format.format,
    current_realm: state.auth.current_realm,
    sort: state.sort,
    table: state.table,
    sites: state.sites,
    issueTypes: state.issuetypes,
    currentModule: state.currentModule,
    colorMode: state.colorMode.colorMode,
    fsr_type: state.auth.fsr_type,
  };
};

export default connect(mapStateToProps, {
  fetchOptimizeDevices,
  fetchDevicesSummary,
  fetchDevices,
  fetchEvents,
  setTableOffset,
  setTablePageSize,
  setTablePage,
  setModule,
  updateSort,
  updateDateRange,
  fetchLocations,
  fetchApplications,
  fetchImportances,
  fetchSites,
  fetchIssueTypes,
  deleteLoops,
  deleteDevices,
  fetchSort,
  fetchLoops,
  updateFilter,
  clearFilter,
})(withStyles(styles)(FSR));
