import React, { useState } from 'react';
import { Typography, Grid, List, Button, ListItem, ListItemText, Collapse, CircularProgress, Tooltip, IconButton } from '@mui/material';
import ChecklistIndicator from '../../components/filters/ChecklistIndicator';
import diagnosticsDetails from '../../arrays/optimize/diagnosticsDetails';
import makeStyles from '@mui/styles/makeStyles';
import { styles } from '../styles';
import imagesArray from '../../arrays/optimize/imagesArray';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import functions from '../../functions/functions';
import { fetchDeviceIssuesByName, fetchActions, fetchInvestigations, fetchIssueDetails, fetchDevices, fetchDeviceSpecs, fetchDiagnostics, setTestStatus } from '../../actions/index';
import InvestigateDetails from '../helpers/InvestigateDetails';

const useStyles = makeStyles(styles);
let requestedInvestigations = [];
let requestedActions = [];
let requestedIssues = [];
let recommendationsCurrent = {};

function renderIssue(props, issue) {
  //const { classes } = props;
  const issueRecommendations = !issue.recommendation_list_active ? [] : issue.recommendation_list_active;
  const issueRecommendationsInactive = !issue.recommendation_list_inactive ? [] : issue.recommendation_list_inactive;
  const issueInvestigations = !issue.investigation_list_active ? [] : issue.investigation_list_active;
  const issueInvestigationsInactive = !issue.investigation_list_inactive ? [] : issue.investigation_list_inactive;
  const combinedRecommendations = issueRecommendations.concat(issueRecommendationsInactive);
  //only ask for things we do not have yet
  let missingRecommendations = [];
  for (let i = 0; i < combinedRecommendations.length; i++) {
    if (!props.recommendations.actions[combinedRecommendations[i]] && !requestedActions.includes(combinedRecommendations[i])) {
      requestedActions.push(combinedRecommendations[i]);
      missingRecommendations.push(combinedRecommendations[i]);
    }
  }
  const combinedInvestigations = issueInvestigations.concat(issueInvestigationsInactive);
  let missingInvestigations = [];
  for (let i = 0; i < combinedInvestigations.length; i++) {
    if (!props.recommendations.investigations[combinedInvestigations[i]] && !requestedInvestigations.includes(combinedInvestigations[i])) {
      requestedInvestigations.push(combinedInvestigations[i]);
      missingInvestigations.push(combinedInvestigations[i]);
    }
  }
  if (!props.recommendations.issues[issue.issue] && !requestedIssues.includes(issue.issue)) {
    requestedIssues.push(issue.issue);
    props.fetchIssueDetails([issue.issue]);
  }
  if (missingInvestigations.length !== 0) {
    props.fetchInvestigations(missingInvestigations);
  }
  if (missingRecommendations.length !== 0) {
    props.fetchActions(missingRecommendations);
  }
  //const issueDetails = !props.recommendations.issues[issue.issue] ? {} : props.recommendations.issues[issue.issue];
  //const issueCharts = !issueDetails.chart_tuple ? [] : issueDetails.chart_tuple;
  //const chartSupplemental = !issueDetails.profilesecondary_tuple && !issueDetails.valveinfo_tuple ? [] : issueDetails.profilesecondary_tuple.concat(issueDetails.valveinfo_tuple);
  //console.log(issueDetails);
}

function getProbabilityColor(props, probability) {
  const { classes } = props;
  switch (probability) {
    case 'Very Likely':
      return classes.greenText;
    case 'Likely':
      return classes.greenText;
    case 'Possible':
      return classes.yellowText;
    case 'Unlikely':
      return classes.redText;
    default:
      return classes.grayText;
  }
}

function RenderRecommendations(propsParent) {
  const props = propsParent.props;
  const issueId = propsParent.issueId;
  const recommendations = propsParent.recommendations;
  const inactive = !propsParent.inactive ? false : propsParent.inactive;
  const { classes } = props;
  const [inactiveOpen, setInactiveOpen] = useState(false);
  //console.log(recommendations);
  let recommendationDetails = props.recommendations.actions;
  //console.log(recommendationDetails);
  if (inactive) {
    return (
      <div className={classes.helperSection}>
        <div
          onClick={() => {
            setInactiveOpen(!inactiveOpen);
          }}
          style={{ marginBottom: 20 }}
        >
          <Typography variant='subtitle1' className={classes.bodyHeaderSelect}>
            Inactive Recommendations
          </Typography>
          <Tooltip arrow placement='top' title={!!inactiveOpen ? 'Collapse' : 'Expand'} aria-label={!!inactiveOpen ? 'Collapse' : 'Expand'}>
            <IconButton style={{ marginTop: -1 }} size='large'>
              {!!inactiveOpen ? <KeyboardArrowUp></KeyboardArrowUp> : <KeyboardArrowDown className={classes.inactiveArrow}></KeyboardArrowDown>}
            </IconButton>
          </Tooltip>
          <Typography variant='caption' className={classes.bodyHeaderSelect}>
            Click to view recommendations that are unlikely to be relevant to this issue
          </Typography>
        </div>
        <Collapse in={inactiveOpen} className={classes.inactiveRec}>
          {recommendations.map((recommendation) => {
            let rowActionId = recommendation;
            if (!recommendationDetails[recommendation]) {
              return (
                <Typography key={`recommendation_row_id${rowActionId}`}>
                  <CircularProgress className={classes.loadIcon} size={15} color='secondary' />
                  Loading Recommendation...
                </Typography>
              );
            }
            return (
              <React.Fragment key={`${recommendation}_recommendation_item`}>
                <ListItem role={undefined} dense style={{ paddingLeft: 0 }}>
                  <ListItemText
                    primary={
                      <Typography variant='subtitle2'>
                        <Grid container>
                          <Grid item style={{ marginRight: 40 }}>
                            {!recommendationDetails[recommendation].action ? 'Recommendation not found!' : recommendationDetails[recommendation].action}
                          </Grid>
                          {/* <Grid item style={{ marginRight: 40 }}>
                          Likelihood -{' '}
                          <span className={getProbabilityColor(props, recommendationDetails[recommendation].probability)}>
                            {!recommendationDetails[recommendation].probability ? 'Likelihood not found!' : recommendationDetails[recommendation].probability}
                          </span>
                        </Grid>
                  <Grid item>Repair Level - {recommendationsCurrent[issueId][rowActionId].level}</Grid>*/}
                        </Grid>
                      </Typography>
                    }
                    classes={props.format ? { primary: classes.listPrimaryFormatted } : { primary: classes.listPrimary }}
                  />
                </ListItem>
                <div style={{ marginBottom: 16 }}>
                  <Typography
                    variant='body1'
                    style={{
                      marginBottom: 16,
                    }}
                    className={classes.body}
                  >
                    {!recommendationDetails[recommendation].comment ? 'Recommendation description not found!' : recommendationDetails[recommendation].comment}
                  </Typography>
                </div>
              </React.Fragment>
            );
          })}
        </Collapse>
      </div>
    );
  } else {
    return (
      <div className={classes.helperSection}>
        <Typography variant='subtitle1' className={classes.bodyHeader}>
          Recommendations
        </Typography>
        {recommendations.length === 0 ? 'No Active Recommendations for this Issue!' : null}
        {recommendations.map((recommendation) => {
          let rowActionId = recommendation;
          if (!recommendationDetails[recommendation]) {
            return (
              <Typography key={`recommendation_row_id${rowActionId}`}>
                <CircularProgress className={classes.loadIcon} size={15} color='secondary' />
                Loading Recommendation...
              </Typography>
            );
          }
          if (typeof recommendationsCurrent[issueId] === 'undefined') {
            recommendationsCurrent[issueId] = {};
          }
          if (typeof recommendationsCurrent[issueId][rowActionId] === 'undefined') {
            recommendationsCurrent[issueId][rowActionId] = {
              checked: false,
              level: recommendationDetails[recommendation].actionType,
            };
          }
          return (
            <React.Fragment key={`${recommendation}_recommendation_item`}>
              <ListItem role={undefined} dense style={{ paddingLeft: 0 }}>
                <ListItemText
                  primary={
                    <Typography variant='subtitle2'>
                      <Grid container>
                        <Grid item className={classes.blueText} style={{ marginRight: 40 }}>
                          {!recommendationDetails[recommendation].action ? 'Recommendation not found!' : recommendationDetails[recommendation].action}
                        </Grid>
                        <Grid item style={{ marginRight: 40 }}>
                          Likelihood -{' '}
                          <span className={getProbabilityColor(props, recommendationDetails[recommendation].probability)}>
                            {!recommendationDetails[recommendation].probability ? 'Likelihood not found!' : recommendationDetails[recommendation].probability}
                          </span>
                        </Grid>
                        <Grid item>Repair Level - {recommendationsCurrent[issueId][rowActionId].level}</Grid>
                      </Grid>
                    </Typography>
                  }
                  classes={props.format ? { primary: classes.listPrimaryFormatted } : { primary: classes.listPrimary }}
                />
              </ListItem>
              <div style={{ marginBottom: 16 }}>
                <Typography
                  variant='body1'
                  style={{
                    marginBottom: 16,
                  }}
                  className={classes.body}
                >
                  {!recommendationDetails[recommendation].comment ? 'Recommendation description not found!' : recommendationDetails[recommendation].comment}
                </Typography>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    );
  }
}

function DiagnosticDetailsDynamic(props) {
  const classes = useStyles();
  const id = props.id;
  const [showMore, setShowMore] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const device = props.devices[props.activeDevice];
  let issueMatch =
    !device || !device.issues || !device.issues[!props.alignment ? 'Technician' : 'Control']
      ? null
      : device.issues[!props.alignment ? 'Technician' : 'Control'].find((obj) => {
          let criticalPass = (props.issue.checkValue === 3 && obj.priority === 2) || (props.issue.checkValue === 2 && obj.priority === 3); //fix for "super reds";
          return obj.issue_name === props.issue.name && (!props.issue.checkValue || props.issue.checkValue === obj.priority || criticalPass);
        });

  if (!!issueMatch && loaded !== 'Loaded') {
    renderIssue(props, issueMatch);
    setLoaded('Loaded');
  }
  if (
    !loaded /* || loadedTest !== props.devices[props.activeDevice].activeTestId*/
    /*!!device &&
    !!props.devices[props.activeDevice].activeTestId &&
    !props.devices[props.activeDevice].issues &&
    !issueMatch /*&& !issueLoad*/
  ) {
    let adj_issue_name = !issueMatch ? '' : issueMatch.issue_name.replace('/', '_');
    if (
      (props.colorValue === 'red' || props.colorValue === 'yellow') &&
      (!props.device_issue_load ||
        !props.device_issue_load[props.activeDevice] ||
        !props.device_issue_load[props.activeDevice]['Technician'] ||
        !props.device_issue_load[props.activeDevice]['Technician'][adj_issue_name] ||
        props.device_issue_load[props.activeDevice]['Technician'][adj_issue_name] === 'empty')
    ) {
      //do nothing.
      setLoaded('loaded');
    } else if (!props.activeDevice || props.activeDevice === '') {
      //props.fetchDeviceIssuesByName(props.activeDevice, 1, !props.alignment ? 'Technician' : 'Control', props.issue.name.replace('/', '_'));
      //props.fetchDeviceIssuesByName(1, 1, !props.alignment ? 'Technician' : 'Control', 'Data Validity');
      setLoaded('loaded');
      console.log('No Device or Test Set Error!');
      //setLoaded('loaded');
    } else {
      //issueLoad = true;
      //loadedTest = props.devices[props.activeDevice].activeTestId;
      //console.log(props);
      props.fetchDeviceIssuesByName(props.activeDevice, props.devices[props.activeDevice].activeTestId, !props.alignment ? 'Technician' : 'Control', props.issue.name.replace('/', '_'));
      setLoaded('loading');
    }
  }
  if (loaded === 'loading') {
    return (
      <Typography style={{ marginRight: 40 }}>
        <CircularProgress className={classes.loadIcon} size={15} color='secondary' />
        Loading...
      </Typography>
    );
  }
  function handleShowMore(e) {
    e.preventDefault();
    setShowMore(!showMore);
  }
  let content = (
    <Grid container spacing={0} justifyContent='space-between'>
      <Grid item>
        {props.activeTab === 'intro' ? (
          <React.Fragment>
            <div className={classes.helperSection}>
              <Typography variant='subtitle1' className={classes.bodyHeader}>
                Description
              </Typography>
              <Typography variant='body1' className={classes.body}>
                {!issueMatch || !issueMatch.description ? `Description Not Found!` : issueMatch.description}
              </Typography>
            </div>
            <div className={classes.helperSection}>
              <Typography variant='subtitle1' className={classes.bodyHeader}>
                Concern Level
              </Typography>
              {!issueMatch || !issueMatch.priority_description ? (
                `Concern Level not listed for this item!`
              ) : (
                <React.Fragment>
                  <div key={`${issueMatch.priority}_priority_item`} style={{ marginBottom: 15 }}>
                    <ChecklistIndicator color={functions.numberToColor(issueMatch.priority)} bodyCheckbox></ChecklistIndicator>
                    <Typography style={{ width: '100%' }} className={classes.body}>
                      <span style={{ fontWeight: 500, textTransform: 'capitalize' }}>{functions.numberToColor(issueMatch.priority)}</span>
                      <br></br>
                      {issueMatch.priority_description}
                    </Typography>
                  </div>
                  <Button onClick={handleShowMore} style={{ marginBottom: 10 }}>
                    {showMore ? (
                      <React.Fragment>
                        <KeyboardArrowUp className={classes.buttonIcon}></KeyboardArrowUp>Show Less
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <KeyboardArrowDown className={classes.buttonIcon}></KeyboardArrowDown>Show More
                      </React.Fragment>
                    )}
                  </Button>
                </React.Fragment>
              )}
              {!issueMatch || !issueMatch.issue_priorities
                ? null
                : issueMatch.issue_priorities.map((priority) => {
                    if (priority.priority === 3 || priority.priority === issueMatch.priority) {
                      return null;
                    } else {
                      return (
                        <Collapse in={showMore} key={`${priority.priority}_priority_collapse`}>
                          {!issueMatch || !issueMatch.issue_priorities ? (
                            `Concern not listed for this item!`
                          ) : (
                            <div key={`${priority.priority}_priority_item`} style={{ marginBottom: 15 }}>
                              <ChecklistIndicator color={functions.numberToColor(priority.priority)} bodyCheckbox></ChecklistIndicator>
                              <Typography style={{ width: '100%' }} className={classes.body}>
                                <span style={{ fontWeight: 500, textTransform: 'capitalize' }}>{functions.numberToColor(priority.priority)}</span>
                                <br></br>
                                {priority.priority_description}
                              </Typography>
                            </div>
                          )}
                        </Collapse>
                      );
                    }
                  })}
            </div>
          </React.Fragment>
        ) : null}
        {props.activeTab === 'recommendations' && !!issueMatch ? (
          <RenderRecommendations props={props} issueId={issueMatch.issue} recommendations={issueMatch.recommendation_list_active} />
        ) : props.activeTab === 'recommendations' ? (
          'No recommendations found for this issue'
        ) : null}
        {props.activeTab === 'recommendations' && !!issueMatch && issueMatch.recommendation_list_inactive.length !== 0 ? (
          <RenderRecommendations props={props} issueId={issueMatch.issue} recommendations={issueMatch.recommendation_list_inactive} inactive={true} />
        ) : null}
        {props.activeTab === 'investigate' && !!issueMatch ? (
          props.activeTab === 'investigate' ? (
            <InvestigateDetails issue={issueMatch} investigations={!issueMatch.investigation_list_active ? [] : issueMatch.investigation_list_active} activeTab={'intro'} />
          ) : (
            'No investigation found for this issue'
          )
        ) : null}
        {props.activeTab === 'examples' ? (
          <div className={classes.helperSection}>
            <Typography variant='subtitle1' className={classes.bodyHeader}>
              Examples
            </Typography>
            <List>
              {!diagnosticsDetails[id] || !diagnosticsDetails[id].examples
                ? `No examples available for this issue. Ref: [${id}]`
                : Object.keys(diagnosticsDetails[id].examples).map((example, index) => {
                    let size = diagnosticsDetails[id].examples[example].size;
                    return (
                      <Grid key={example} container style={{ marginBottom: 30 }}>
                        <Grid item xs={12}>
                          <Typography variant='subtitle2' className={classes.body} color='secondary' style={{ width: '100%' }}>
                            <span style={{ fontWeight: 500 }}>Example {index + 1}</span> - {diagnosticsDetails[id].examples[example].title}
                          </Typography>
                          <Typography variant='body1' className={classes.body}>
                            <span style={{ fontWeight: 500 }}>Example {index + 1}</span> - {diagnosticsDetails[id].examples[example].caption}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <img
                            key={`img-${diagnosticsDetails[id].examples[example].src}`}
                            src={imagesArray[diagnosticsDetails[id].examples[example].src]}
                            alt={diagnosticsDetails[id].examples[example].caption}
                            className={classes.helperScreenshot}
                            style={{
                              maxWidth: !!props.resize ? 1000 : size === 'xs' ? 340 : size === 'sm' ? 400 : size === 'md' ? 500 : 800,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Grid
                            container
                            justifyContent='space-between'
                            style={{
                              maxWidth: !!props.resize ? 1000 : size === 'xs' ? 340 : size === 'sm' ? 400 : size === 'md' ? 500 : 800,
                            }}
                          >
                            <Grid item></Grid>
                            <Grid item>
                              <Typography variant='caption' style={{ fontStyle: 'italic' }}>
                                Example {index + 1} image
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
            </List>
          </div>
        ) : null}
      </Grid>
    </Grid>
  );
  return content;
}

const mapStateToProps = (state) => ({
  activeDevice: state.optimize.activeDevice,
  devices: state.devices.devices,
  currentModule: state.currentModule.currentModule,
  userId: state.auth.userId,
  clientPermission: state.auth.clientPermission,
  recommendations: state.recommendations,
  tests: state.tests.tests,
  tests_status: state.tests.status,
  device_issue_load: state.devices.device_issue_load,
});

export default connect(mapStateToProps, {
  fetchDeviceIssuesByName,
  fetchActions,
  fetchInvestigations,
  fetchIssueDetails,
  fetchDevices,
  fetchDeviceSpecs,
  fetchDiagnostics,
  setTestStatus,
})(withStyles(styles)(DiagnosticDetailsDynamic));
