import React from 'react';
import { Button, Grid, Typography, Accordion, AccordionSummary, AccordionDetails, Tooltip, Paper, Divider, CircularProgress, Autocomplete, TextField } from '@mui/material';
import InfoPopover from '../popup/InfoPopover';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../styles';
import CustomSelect from '../filters/CustomSelect';
import { fetchLoop, fetchLoopEvents, fetchLoopActionItems, changeEventSelections } from '../../actions/index';
import api from '../../apis/api';
import { ExpandMore } from '@mui/icons-material';
import ChecklistIndicator from '../filters/ChecklistIndicator';
import functions from '../../functions/functions';
import EventContent from '../widgetGroups/prioritize/content/EventContent';

class AccordionWidgetPrioritize extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowOpen: false,
      loading: false,
      status: '',
      statusMessage: '',
      openAccordion: -1,
      activeActions: {},
    };
    this.handleClick = this.handleClick.bind(this);
    //this.submitPreGenActions = this.submitPreGenActions.bind(this);
    //this.fetchPreGenActionItems = this.fetchPreGenActionItems.bind(this);
    //this.renderChildRowContent = this.renderChildRowContent.bind(this);
  }
  preGenActionItemsCurrent = {};
  preGenActionItems = {};
  async componentDidMount() {
    //console.log(this.props.activeLoop);
    this.props.fetchLoopEvents(this.props.activeLoop, { analysisRange: !this.props.analysisRange ? 90 : this.props.analysisRange }, this.props.dataset);
    this.fetchPreGenActionItems();
  }
  fetchPreGenActionItems = async () => {
    const response = await api.get('prioritize/actions/preGen');
    this.preGenActionItems = response.data.data;
    this.setState({ state: this.state });
  };
  processDate(timestamp) {
    //return timestamp;
    return new Date(timestamp * 1000).toDateString();
  }
  processDuration(duration) {
    return `${Math.round(duration / (60 * 60 * 24))} Days`;
  }
  processDeviceTypes(deviceId) {
    let types = [];

    for (let i = 0; i < deviceId.length; i++) {
      if (!!this.props.devices[deviceId[i]] && types.indexOf(this.props.devices[deviceId[i]].deviceType) === -1) {
        types.push(this.props.devices[deviceId[i]].deviceType);
      }
    }

    return types;
  }
  getDeviceIds = (deviceNameArr, loopId) => {
    let loop = this.props.loops[loopId];
    let deviceIds = [];
    for (let i = 0; i < deviceNameArr.length; i++) {
      let index = loop.devices.indexOf(deviceNameArr[i]);
      deviceIds.push(loop.device_ids[index]);
    }
    return deviceIds;
  };
  submitPreGenActions = async (index) => {
    let errorStatus = false;
    let errorMessage = '';
    try {
      const url = `/prioritize/loops/${this.props.activeLoop}/improvements`;
      //let selectedItems = [];
      let selectedLoop = this.props.activeLoop;
      for (const actionItemKey in this.preGenActionItemsCurrent) {
        let actionItem = this.preGenActionItemsCurrent[actionItemKey];
        if (actionItem.row === index && actionItem.checked) {
          let currentState = {
            comment: actionItem.comment,
            device: this.getDeviceIds(actionItem.devices, selectedLoop).join(','),
            improvement: actionItem.action,
            improvementType: actionItem.type,
            issue: !actionItem.name ? actionItem.issue : actionItem.name, //fix??
            level: functions.importanceToValue(actionItem.priority), //importance?
            levelName: actionItem.priority,
            issuePriority: actionItem.issuePriority,
            actionRangeStart: this.props.actionRange.start,
            actionRangeEnd: this.props.actionRange.end,
          };
          if (currentState.device === '') {
            errorStatus = true;
            errorMessage = errorMessage + (errorMessage === '' ? '' : ', ') + `Please Select device(s) for '${actionItem.action}'`;
          } else if (errorStatus === false) {
            this.setState({
              loading: true,
              status: 'Loading',
              statusMessage: 'Loading...',
            });
            //console.log(currentState);
            await api.post(url, [
              currentState,
              selectedLoop,
              //selectedTestValve,
            ]);
            this.setState({
              status: 'Success',
              statusMessage: 'Action Items Submitted',
              loading: false,
            });
          }

          //TODO ADD PROCESSING/SUCCESS NOTIFICATION
          //selectedItems.push(actionItem);
        }
      }
    } catch (e) {
      //alert(e);
      this.setState({
        status: 'Failure',
        statusMessage: 'Failure!',
        loading: false,
      });
      console.log(e);
    }
    if (errorStatus) {
      this.setState({ status: 'Failure', statusMessage: errorMessage });
    } else {
      this.preGenActionItemsCurrent = {};
      //console.log(this.preGenActionItemsCurrent);
      this.setState();
      this.props.fetchLoopEvents(this.props.activeLoop, { analysisRange: !this.props.analysisRange ? 90 : this.props.analysisRange }, this.props.dataset);
      this.props.fetchLoopActionItems(this.props.activeLoop);
    }
    //console.log(selectedItems);
  };
  handleClick = (e, id) => {
    this.preGenActionItemsCurrent[id].checked = !this.preGenActionItemsCurrent[id].checked;
    this.setState({ state: this.state });
  };
  getHighestImportance = (row) => {
    const loop = this.props.loops[this.props.activeLoop];
    const eventDevices = row.deviceNameArr;
    let defaultImportance = 'Unknown';
    for (let i = 0; i < eventDevices.length; i++) {
      const index = loop.devices.indexOf(eventDevices[i][0]);

      const importance = loop.importance[index];
      if (functions.importanceToValue(importance) > functions.importanceToValue(defaultImportance)) {
        defaultImportance = importance;
      }
    }
    return defaultImportance;
  };
  getOptions = (row) => {
    const preGens = !this.preGenActionItems || !this.preGenActionItems[row.issue] ? [] : this.preGenActionItems[row.issue];
    let options = [];
    options = preGens.map((action) => {
      return action.action;
    });
    return options;
  };
  handlePreGenClick(event, values, rowId) {
    let activeActions = this.state.activeActions;
    activeActions[rowId] = values;
    this.setState({ activeActions: activeActions });
  }
  handleCommentChange() {}
  renderChildRowContent = (row, rowIndex) => {
    //console.log(this.preGenActionItems[row.issue]);
    //console.log(row);
    //console.log(!this.props.events ? 'loading' : this.props.events[`${this.props.activeLoop}${row.issue}`]);
    //const event = !this.props.events ? {} : this.props.events[`${this.props.activeLoop}${row.issue}`];
    const { classes } = this.props;
    const color = this.state.status === 'Failure' ? 'error' : 'secondary';
    const preGens = !this.preGenActionItems || !this.preGenActionItems[row.issue] ? [] : this.preGenActionItems[row.issue];
    const defaultImportance = this.getHighestImportance(row);
    //console.log(this.state.activeActions[rowIndex]);
    let preGenFilter = !this.state.activeActions[rowIndex]
      ? []
      : preGens.filter((item) => {
          return Object.keys(item).some((key) => this.state.activeActions[rowIndex].includes(item[key]));
        });
    let preGenNotFilter = !this.state.activeActions[rowIndex]
      ? []
      : preGens.filter((item) => {
          return !Object.keys(item).some((key) => this.state.activeActions[rowIndex].includes(item[key]));
        });
    preGenNotFilter.map((action) => {
      let rowActionId = `${row.id}_${action.id}`;
      //initialize the preGenActionItemsCurrent state for each ID once and only once
      if (!!this.preGenActionItemsCurrent[rowActionId]) {
        this.preGenActionItemsCurrent[rowActionId].checked = false;
      }
      return null;
    });
    //console.log(preGenFilter);

    return (
      <div className={classes.avoidBreak}>
        <EventContent row={row} rowIndex={rowIndex}></EventContent>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            //this.setState({ changeConfirm: true });
          }}
        >
          <div className={classes.printHide}>
            {' '}
            <div className={classes.dividerXl}></div>
            {!preGens.length > 0 ? (
              <Typography variant='subtitle1' color='secondary' style={{ marginBottom: 15 }}>
                No Auto-Generated Action Items Found For This Issue
              </Typography>
            ) : (
              <React.Fragment>
                <Typography variant='subtitle1' color='secondary' style={{ marginBottom: 5 }}>
                  Auto-Generated Action Items
                </Typography>
                <Typography variant='body2' color='primary' style={{ marginBottom: 15 }}>
                  Please select desired action items, then click submit to add them to the action items table
                </Typography>
              </React.Fragment>
            )}
            <Autocomplete
              disablePortal
              disableCloseOnSelect
              multiple
              id='combo-box-demo'
              options={this.getOptions(row)}
              getOptionLabel={(option) => option.toString()}
              sx={{ maxWidth: 400 }}
              onChange={(event, values) => {
                this.handlePreGenClick(event, values, row.id);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder='Select Auto-Generated Action Items'
                  variant='outlined'
                  inputProps={{
                    classes: {},
                    ...params.inputProps,
                  }}
                />
              )}
              style={{ marginBottom: 24 }}
            />
            {preGenFilter.map((action) => {
              let rowActionId = `${row.id}_${action.id}`;
              //initialize the preGenActionItemsCurrent state for each ID once and only once
              if (!this.preGenActionItemsCurrent[rowActionId]) {
                let checkboxState = {
                  [rowActionId]: {
                    issue: row.issue,
                    row: row.id,
                    action_id: action.id,
                    action: action.action,
                    comment: action.comment,
                    type: action.type,
                    devices: row.deviceNameArr.map((device) => device[0]),
                    priority: defaultImportance,
                    checked: true,
                    issuePriority: row.issuePriority,
                  },
                };
                this.preGenActionItemsCurrent = Object.assign(this.preGenActionItemsCurrent, checkboxState);
              }
              return (
                <div key={`${rowActionId}_item`} style={{ marginBottom: 24 }}>
                  <Typography variant='subtitle2'>{action.action}</Typography>
                  {
                    /*TODO Prio_1.0 for custom action item only */
                    this.preGenActionItemsCurrent[rowActionId].action !== 'Add Action Item' ? null : (
                      <TextField
                        label='Action Item Name'
                        variant='outlined'
                        defaultValue={
                          !this.preGenActionItemsCurrent[rowActionId] || !this.preGenActionItemsCurrent[rowActionId].name ? 'Custom Action Item' : this.preGenActionItemsCurrent[rowActionId].name
                        }
                        style={{ marginTop: 18, marginBottom: 12 }}
                        InputLabelProps={{
                          classes: {
                            root: classes.inputLabelFix,
                          },
                        }}
                        onChange={(event) => {
                          this.preGenActionItemsCurrent[rowActionId].name = event.target.value;
                          //this.setState({ state: this.state });
                        }}
                      />
                    ) /**/
                  }
                  <Grid container>
                    <Grid item md={12} lg={8}>
                      <TextField
                        id='comment'
                        label='Comment'
                        name='comment'
                        multiline
                        fullWidth
                        variant='outlined'
                        defaultValue={
                          !this.preGenActionItemsCurrent[rowActionId] || !this.preGenActionItemsCurrent[rowActionId].comment ? action.comment : this.preGenActionItemsCurrent[rowActionId].comment
                        }
                        onChange={(event) => {
                          this.preGenActionItemsCurrent[rowActionId].comment = event.target.value;
                          //this.setState({ state: this.state });
                        }}
                        style={{ marginTop: 12, marginBottom: 12 }}
                        InputLabelProps={{
                          classes: {
                            root: classes.inputLabelFix,
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                  {/*<Typography
                  variant='body2'
                  style={{
                    marginBottom: 16,
                  }}
                >
                  {action.comment}
                </Typography>*/}
                  <CustomSelect
                    single
                    id='improvementType'
                    label='Action Item Type'
                    type='improvementType'
                    name='improvementType'
                    disabled={this.state.loading}
                    //onCloseFunction={this.multiSelectOnClose}
                    onChangeFunction={(event, value) => {
                      this.preGenActionItemsCurrent[rowActionId].type = value;
                      //this.setState({ state: this.state });
                    }}
                    //type='unit'
                    values={this.preGenActionItemsCurrent[rowActionId].type}
                    options={['Investigate', 'On-Line Improvement', 'Outage Required', 'Application Shutdown', 'Plant Shutdown Required', 'To Be Determined', 'Note']}
                    //value={null}
                    helperText={null}
                  />
                  <CustomSelect
                    id={null}
                    label='Devices'
                    disabled={this.state.loading}
                    //values={!this.props.sort.filter.Site ? [] : this.props.sort.filter.Site}
                    //type='site'
                    options={
                      !this.props.activeLoop || !this.props.loops || !this.props.loops[this.props.activeLoop] || !this.props.loops[this.props.activeLoop].devices
                        ? !row.deviceNameArr
                          ? []
                          : row.deviceNameArr.map((i) => i[0])
                        : this.props.loops[this.props.activeLoop].devices
                    }
                    //onCloseFunction={this.multiSelectOnClose}
                    onChangeFunction={(event, value, reason) => {
                      this.preGenActionItemsCurrent[rowActionId].devices = value;
                    }}
                    //onChangeFunction={this.multiSelectOnChange}
                    /*selectedOption={
                        !row.deviceNameArr ? [] : row.deviceNameArr
                      }*/
                    selectedOption={!this.preGenActionItemsCurrent[rowActionId].devices ? [] : this.preGenActionItemsCurrent[rowActionId].devices}
                    helperText={null}
                  />
                  <CustomSelect
                    single
                    id='priority'
                    label='Priority'
                    type='priority'
                    name='priority'
                    disabled={this.state.loading}
                    //onCloseFunction={this.multiSelectOnClose}
                    onChangeFunction={(event, value) => {
                      this.preGenActionItemsCurrent[rowActionId].priority = value;
                      //this.setState({ state: this.state });
                    }}
                    //type='unit'
                    values={this.preGenActionItemsCurrent[rowActionId].priority}
                    options={['Unknown', 'Not Important', 'Standard', 'Important', 'Very Important', 'Extremely Important']}
                    //value={null}
                    helperText={null}
                  />
                </div>
              );
            })}
            {!preGens.length > 0 ? null : (
              <React.Fragment>
                <Divider className={`${classes.dialogDivider} ${classes.accordionDialogDivider}`}></Divider>
                <Grid container justifyContent='space-between'>
                  <Grid item>
                    {this.state.status !== '' ? (
                      <Typography color={color} variant='subtitle1' style={{ marginTop: 3 }}>
                        {`${this.state.statusMessage}`}
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item>
                    <Button type='text' disabled={this.state.loading} onClick={() => this.submitPreGenActions(row.id)} color='secondary'>
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </React.Fragment>
            )}
          </div>
        </form>
      </div>
    );
  };
  processActiveDuration(incidentTimes) {
    let startTime = 0;
    let endTime = 0;
    let totalTime = 0;
    for (let i = 0; i < incidentTimes.length; i++) {
      if (!(incidentTimes[i][0] >= startTime && incidentTimes[i][1] <= endTime)) {
        //prevents events that occur inside another larger event from being counted and causing issues
        if (incidentTimes[i][0] > startTime) {
          totalTime = totalTime + (endTime - startTime);
          startTime = incidentTimes[i][0];
        }
        if (incidentTimes[i][1] > endTime) {
          endTime = incidentTimes[i][1];
        }
      }
    }
    totalTime = totalTime + (endTime - startTime); //endingValue
    return totalTime;
  }

  // END Of LINE
  /*
  convertChartTypeNum(num) {
    switch (num) {
      case '1':
        return { attribute: 'Travel', idType: 'Profile' };
      case '2':
        return { attribute: 'Air', idType: 'Profile' };
      case '3':
        return { attribute: 'Actuator', idType: 'Profile' };
      case '4':
        return { attribute: 'Friction', idType: 'Profile' };

      case '5':
        return { attribute: 'I/P', idType: 'Profile' };
      case '6':
        return { attribute: 'Seat', idType: 'Profile' };
      case '7':
        return { attribute: 'Resolution', idType: 'Resolution' };
      case '8':
        return { attribute: 'Sensitivity', idType: 'Sensitivity' };
      case '9':
        return { attribute: 'Stroke', idType: 'Stroke' };

      default:
        return { attribute: 'Error', idType: 'Error' };
    }
  }
  processSupplementalType(infoType) {
    let device = this.props.devices[this.props.activeDevice];
    let testId = !device ? null : device.activeProfileId;
    let specs =
      !this.props.tests || !this.props.tests.diagnosticsSpecs
        ? null
        : this.props.tests.diagnosticsSpecs[testId];
    if (!this.props.tests || !this.props.tests.diagnosticsSpecs) {
      return { title: 'Loading', value: 'N/A' };
    }
    switch (infoType) {
      case 'seatContact':
        return { title: 'Seat Contact', value: specs.seatContact };
      case 'fullOpen':
        return { title: 'Full Open', value: specs.fullOpen };
      case 'signalSource':
        return { title: 'Signal Source', value: device.signalSource };
      default:
        return 'Info';
    }
  }
  renderIssueSupplemental(chartSupplemental) {
    return (
      <Grid container>
        {chartSupplemental.map((infoType) => {
          let data = this.processSupplementalType(infoType);
          return (
            <TextDisplay
              key={`supplemental_${infoType}`}
              id={null}
              label={data.title}
              text={!data.value ? 'Error' : data.value}
              type='tile'
              hasBottomMargin
            />
          );
        })}
      </Grid>
    );
  }
  renderIssueCharts(chartDetails) {
    const { classes } = this.props;
    return (
      <Grid container>
        {chartDetails.map((chartTypeId) => {
          let chartType = this.convertChartTypeNum(chartTypeId.toString());
          return (
            <Grid
              key={`chartType_key_${chartTypeId}`}
              item
              xs={12}
              sm={6}
              md={4}
            >
              <ValveChartWidget
                className={classes.widgetSpacing}
                xs={12}
                type='valve'
                valveColumns={1}
                progress={false}
                testId={
                  !this.props.devices ||
                  !this.props.devices[this.props.activeDevice]
                    ? null
                    : this.props.devices[this.props.activeDevice][
                        `active${chartType.idType}Id`
                      ]
                }
                attribute={chartType.attribute}
                loadingValidation={'single'}
                embed
                chartType={chartType}
              ></ValveChartWidget>
            </Grid>
          );
        })}
      </Grid>
    );
  }
  getProbabilityColor(probability) {
    const { classes } = this.props;
    switch (probability) {
      case 'Very Likely':
      return classes.greenText;
    case 'Likely':
      return classes.greenText;
    case 'Possible':
      return classes.yellowText;
    case 'Unlikely':
      return classes.redText;
    default:
      return classes.grayText;
    }
  }
  handleRecommendationClick(e, issue, id) {
    this.recommendationsCurrent[issue][id].checked =
      !this.recommendationsCurrent[issue][id].checked;
    this.setState({ state: this.state });
  }
  renderRecommendations(issueId, recommendations) {
    const { classes } = this.props;
    //console.log(recommendations);
    let recommendationDetails = this.props.recommendations.actions;
    //console.log(recommendationDetails);
    return (
      <React.Fragment>
        {recommendations.map((recommendation) => {
          let rowActionId = recommendation;
          if (!recommendationDetails[recommendation]) {
            return (
              <Typography key={`recommendation_row_id${rowActionId}`}>
                Loading...
              </Typography>
            );
          }
          if (typeof this.recommendationsCurrent[issueId] === 'undefined') {
            this.recommendationsCurrent[issueId] = {};
          }
          if (
            typeof this.recommendationsCurrent[issueId][rowActionId] ===
            'undefined'
          ) {
            this.recommendationsCurrent[issueId][rowActionId] = {
              checked: false,
              level: recommendationDetails[recommendation].actionType,
            };
          }

          return (
            <React.Fragment key={`${recommendation}_recommendation_item`}>
              <ListItem
                role={undefined}
                dense
                //className={this.preGenActionItemsCurrent[rowActionId].checked ? classes.checklistItemActive : classes.checklistItemSelectable}
                style={{ cursor: 'pointer', paddingLeft: 0 }}
              >
                <Checkbox
                  checked={
                    this.recommendationsCurrent[issueId][rowActionId].checked
                  }
                  onClick={(e) => {
                    this.handleRecommendationClick(e, issueId, rowActionId);
                  }}
                  className={classes.checkbox}
                  style={{ marginRight: 8 }}
                  color='secondary'
                ></Checkbox>
                <ListItemText
                  onClick={(e) => {
                    this.handleRecommendationClick(e, issueId, rowActionId);
                  }}
                  primary={
                    <Typography variant='subtitle2'>
                      <Grid container>
                        <Grid item style={{ marginRight: 40 }}>
                          {!recommendationDetails[recommendation].action
                            ? 'Recommendation not found!'
                            : recommendationDetails[recommendation].action}
                        </Grid>
                        <Grid item>
                          Confidence -{' '}
                          <span
                            className={this.getProbabilityColor(
                              recommendationDetails[recommendation].probability
                            )}
                          >
                            {!recommendationDetails[recommendation].probability
                              ? 'Confidence not found!'
                              : recommendationDetails[recommendation]
                                  .probability}
                          </span>
                        </Grid>
                      </Grid>
                    </Typography>
                  }
                  classes={
                    this.props.format
                      ? { primary: classes.listPrimaryFormatted }
                      : { primary: classes.listPrimary }
                  }
                />
              </ListItem>
              <Collapse
                in={this.recommendationsCurrent[issueId][rowActionId].checked}
                style={{ marginLeft: 22, marginTop: 8 }}
              >
                <div style={{ marginBottom: 24 }}>
                  <Typography
                    variant='body2'
                    style={{
                      cursor: 'pointer',
                      marginLeft: 22,
                      marginBottom: 16,
                    }}
                    className={classes.body}
                  >
                    {!recommendationDetails[recommendation].comment
                      ? 'Recommendation description not found!'
                      : recommendationDetails[recommendation].comment}
                  </Typography>
                  <div
                    style={{
                      marginLeft: 22,
                      marginBottom: 16,
                    }}
                  >
                    <CustomSelect
                      single
                      id={null}
                      label={`Repair Level`}
                      onChangeFunction={(event, value, type) => {
                        this.recommendationsCurrent[issueId][
                          rowActionId
                        ].level = value;
                        this.setState({ state: this.state });
                      }}
                      values={
                        this.recommendationsCurrent[issueId][rowActionId].level
                      }
                      options={[
                        'Field Adjustment',
                        'Part Replacement',
                        'Body Repair',
                        'Note',
                      ]}
                      helperText={null}
                    />
                  </div>
                </div>
              </Collapse>
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
  }

  handleInvestigateClick = (event) => {
    event.stopPropagation();
  };
  handleMenuClick = (event) => {
    event.stopPropagation();
    this.setState({
      statusMenu: !this.state.statusMenu,
      statusMenuAnchor: event.currentTarget,
    });
  };
  */
  handleInfoClick = (event) => {
    event.stopPropagation();
  };
  handleAccordionClick = (index) => {
    //console.log(index);
    if (this.state.openAccordion === index) {
      this.setState({ openAccordion: -1 });
    } else {
      this.setState({ openAccordion: index });
    }
  };
  //!duplicate function from EventContent.js
  consolidateEvents(events) {
    const eventList = !events.incidentTimes ? [] : events.incidentTimes;
    const eventDevices = !events.incidentDevices ? [] : events.incidentDevices;
    const eventDeviceNames = !events.incidentDeviceNames ? [] : events.incidentDeviceNames;
    const eventSelection = !events.selected ? [] : events.selected;
    let eventListCombined = [];
    let eventDevicesCombined = [];
    let eventDeviceNamesCombined = [];
    let selectedCombined = [];
    let tempEventStart = 0;
    let tempEventEnd = 0;
    let tempEventDevices = [];
    let tempEventDeviceNames = [];
    let tempSelected = false;

    for (let i = 0; i < eventList.length; i++) {
      if (tempEventStart === 0) {
        //first entry
        tempEventStart = eventList[i][0];
        tempEventEnd = eventList[i][1];
        tempEventDevices = eventDevices[i];
        tempEventDeviceNames = eventDeviceNames[i];
      } else if (tempEventEnd !== 0 && eventList[i][0] > tempEventEnd) {
        //push finished event
        eventListCombined.push([tempEventStart, tempEventEnd]);
        eventDevicesCombined.push(tempEventDevices);
        eventDeviceNamesCombined.push(tempEventDeviceNames);
        selectedCombined.push(tempSelected);
        //start new event
        tempEventStart = eventList[i][0];
        tempEventEnd = eventList[i][1];
        tempEventDevices = eventDevices[i];
        tempEventDeviceNames = eventDeviceNames[i];
        tempSelected = eventSelection[i];
      } else if (eventList[i][1] >= tempEventEnd) {
        //event continues or extends exisiting
        if (eventSelection[i] === true) {
          tempSelected = true;
        }
        tempEventEnd = eventList[i][1];
        for (let j = 0; j < eventDevices[i].length; j++) {
          if (!tempEventDevices.includes(eventDevices[i][j])) {
            tempEventDevices.push(eventDevices[i][j]);
            tempEventDeviceNames.push(eventDeviceNames[i][j]);
          }
        }
      } else if (eventList[i][0] >= tempEventStart) {
        //event occurs inside existing event
        if (eventSelection[i] === true) {
          tempSelected = true;
        }
        for (let j = 0; j < eventDevices[i].length; j++) {
          if (!tempEventDevices.includes(eventDevices[i][j])) {
            tempEventDevices.push(eventDevices[i][j]);
            tempEventDeviceNames.push(eventDeviceNames[i][j]);
          }
        }
      }
    }
    //push last event in buffer
    eventListCombined.push([tempEventStart, tempEventEnd]);
    eventDevicesCombined.push(tempEventDevices);
    eventDeviceNamesCombined.push(tempEventDeviceNames);
    if (eventSelection[eventList.length - 1] === true) {
      tempSelected = true;
    }
    selectedCombined.push(tempSelected);
    let tempEvents = Object.assign({}, events);
    tempEvents.incidentTimesConsolidated = eventListCombined;
    tempEvents.incidentDevicesConsolidated = eventDevicesCombined;
    tempEvents.incidentDeviceNamesConsolidated = eventDeviceNamesCombined;
    tempEvents.selectedConsolidated = !!events.selectedConsolidated ? events.selectedConsolidated : selectedCombined;
    tempEvents.eventCountConsolidated = eventListCombined.length;
    return tempEvents;
  }
  renderIssue(issue, index) {
    //console.log(issue);
    const { classes } = this.props;

    const event = !this.props.events[issue] ? this.props.events[issue] : this.consolidateEvents(this.props.events[issue]); //weird check but it works
    //console.log(issue);
    //console.log(event);
    //console.log(index);
    if (!event) {
      return null;
    }
    const activeDuration = this.processActiveDuration(event.incidentTimes);
    const row = {
      id: index,
      deviceName: event.deviceName.join(', '),
      deviceNameArr: event.deviceName,
      deviceIdArr: event.device_id,
      deviceType: this.processDeviceTypes(event.device_id).sort().join(', '), //'tbd', //this.processDeviceType(event.device_id),
      issue: event.eventName,
      startDate: this.processDate(event.eventStart),
      duration: this.processDuration(activeDuration),
      count: event.eventCountConsolidated,
      openActions: !event.openActions ? 0 : event.openActions,
      issuePriority: event.priority,
    };
    const printStyles = functions.setPrintStyles(this.props.activeTab);
    return (
      <div className={classes.avoidBreak} key={`issue_key_${event.id}`}>
        <Accordion expanded={index === this.state.openAccordion} TransitionProps={{ unmountOnExit: true }} square={false} className={printStyles ? classes.printIssuePaper : classes.issuePaper}>
          <AccordionSummary
            className={classes.accordionSummary}
            expandIcon={
              <Tooltip arrow placement='top' title='Expand/Collapse' enterDelay={300}>
                <ExpandMore className={classes.iconGray} />
              </Tooltip>
            }
            onClick={() => {
              this.handleAccordionClick(index);
            }}
            aria-controls='test'
            id='test'
          >
            <Grid container justifyContent='space-between'>
              <Grid item style={{ paddingTop: 0, minWidth: 240 }}>
                <Grid container>
                  <Grid item onClick={this.handleInfoClick}>
                    <InfoPopover resize title={event.eventName} colorValue={functions.numberToColor(event.priority)} usePrioritizeTabs id={row} type='prioritize' />
                  </Grid>
                  <Grid item>
                    <div style={{ marginTop: 6 }}>
                      <ChecklistIndicator color={functions.numberToColor(event.priority)} tooltip={functions.numberToPrioritizeTooltip(event.priority)}></ChecklistIndicator>
                    </div>
                  </Grid>
                  <Grid item>
                    {!event.eventName ? (
                      <Typography variant='subtitle2' style={{ paddingLeft: 3, paddingTop: 3 }}>
                        <CircularProgress className={classes.loadIcon} size={15} color='secondary' />
                        Loading...
                      </Typography>
                    ) : (
                      <Typography variant='subtitle2' style={{ paddingLeft: 3, paddingTop: 3 }}>
                        {event.eventName}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item style={{ paddingTop: 3, width: 100 }}>
                <Typography variant='subtitle2'>
                  {event.deviceName.length} Device
                  {event.deviceName.length === 1 ? null : 's'}
                </Typography>
              </Grid>
              <Grid item style={{ paddingTop: 3, width: 160 }}>
                <Typography variant='subtitle2'>{this.processDeviceTypes(event.device_id).sort().join(', ')}</Typography>
              </Grid>
              <Grid item style={{ paddingTop: 3, width: 100 }}>
                <Typography variant='subtitle2'>{this.processDuration(activeDuration)}</Typography>
              </Grid>
              <Grid item style={{ paddingTop: 3, width: 100 }}>
                <Typography variant='subtitle2'>
                  {event.eventCountConsolidated} Event
                  {event.eventCountConsolidated === 1 ? null : 's'}
                </Typography>
              </Grid>
              <Grid item>
                <Grid container style={{ minWidth: 60 }} justifyContent='space-between'>
                  {/*issueInvestigations < 1 ? null : (
                  <span onClick={this.handleInvestigateClick}>
                    <InfoPopover
                      center
                      tooltip='Investigate this Issue'
                      title={`Investigate - ${
                        !device || !device.name
                          ? 'Device Name Not Found'
                          : device.name
                      }`}
                      titleIcon='Investigate'
                      resize
                      type='investigate'
                      useInvestigateTabs
                      icon={<Search></Search>}
                      issue={issue}
                      investigations={issueInvestigations}
                    />
                  </span>
                    )*/}
                  {/*<Tooltip placement="top"  title='Update Status' enterDelay={300}>
                  <IconButton
                    color='inherit'
                    aria-label='more'
                    onClick={this.handleMenuClick}
                    size='large'
                  >
                    <MoreVert />
                  </IconButton>
                  </Tooltip>*/}
                  {/*<Popper
                  open={this.state.statusMenu}
                  anchorEl={this.state.statusMenuAnchor}
                  onClose={handleStatusClose}
                  placement='bottom-start'
                >
                  <ClickAwayListener onClickAway={handleStatusClose}>
                    <Paper>
                      <Typography
                        variant='caption'
                        style={{
                          padding: '10px 14px 6px 14px',
                          display: 'block',
                        }}
                      >
                        Select to update status
                      </Typography>
                      <MenuItem>Detected</MenuItem>
                      <MenuItem>Acknowledged</MenuItem>
                      <MenuItem>Rejected</MenuItem>
                      <MenuItem>In Progress</MenuItem>
                      <MenuItem>Resolved</MenuItem>
                    </Paper>
                  </ClickAwayListener>
                      </Popper>*/}
                </Grid>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            {this.renderChildRowContent(row, index)}
            {/*
          <div className={classes.helperSection}>
            <Typography
              variant='subtitle1'
              color='secondary'
              style={{ marginBottom: 5 }}
            >
              Description
            </Typography>
            <Typography variant='body1' className={classes.body}>
              {issueDetails.description}
            </Typography>
            {this.renderIssueCharts(issueCharts)}
            {this.renderIssueSupplemental(chartSupplemental)}
          </div>
          {issueInvestigations < 1 ? null : (
            <div className={classes.helperSection}>
              <Typography
                variant='subtitle1'
                color='secondary'
                style={{ marginBottom: 5 }}
              >
                Investigation
              </Typography>
              <Typography style={{ marginBottom: 10 }}>
                Unsure of next steps? Click below to investigate the issue and
                view examples.
              </Typography>
              <InfoPopover
                center
                button
                tooltip='Investigate this Issue'
                title={`Investigate - ${
                  !device || !device.name
                    ? 'Device Name Not Found'
                    : device.name
                }`}
                titleIcon='Investigate'
                resize
                type='investigate'
                useInvestigateTabs
                icon={<Search className={classes.buttonIcon}></Search>}
                issue={issue}
                investigations={issueInvestigations}
              />
            </div>
          )}
          <Typography
            variant='subtitle1'
            color='secondary'
            style={{ marginBottom: 5 }}
          >
            Recommendations
          </Typography>
          {this.renderRecommendations(issue.issue, issueRecommendations)}
          <Typography>
            Don't see the improvement path you need? Create a custom improvement
            path below.
          </Typography>
          <Divider
            className={`${classes.dialogDivider} ${classes.accordionDialogDivider}`}
          ></Divider>
          <Grid container justifyContent='space-between'>
            <Grid item></Grid>
            <Grid item>
              <Button
                type='text'
                onClick={(e) => {
                  this.handleRecommendationSubmit(
                    issue.issue,
                    this.recommendationsCurrent[issue.issue]
                  );
                }}
                color='secondary'
              >
                Submit
              </Button>
            </Grid>
          </Grid>*/}
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }
  /*
  handleRecommendationSubmit(issueId, recommendations) {
    console.log(issueId, recommendations);
  }

  handleSubmit = async () => {
    let test = this.state.test;
    if (test === null) {
      test =
        !this.props.devices[this.props.activeDevice] ||
        !this.props.devices[this.props.activeDevice].dateTime
          ? null
          : `${new Date(
              this.props.devices[this.props.activeDevice].dateTime * 1000
            ).toLocaleString()}${this.processState(
              this.props.devices[this.props.activeDevice].state
            )}`;
      this.setState({
        test,
      });
    }
    const selectedTest = this.selectActiveTest(test);
    const selectedTestValve = this.selectActiveTestValve(test);
    //console.log(selectedTestValve);
    //console.log(selectedTest);
    //console.log(this.state);
    //this.props.addImprovement(this.state, this.selectActiveTest(test),this.props.devices[this.props.activeDevice] );
    //console.log(this.props.activeDevice);
    //console.log(this.props.devices[this.props.activeDevice]);
    const url = this.state.formBool
      ? `/${this.props.currentModule}/devices/${this.props.activeDevice}/improvements/update/${this.state.editId}`
      : `/${this.props.currentModule}/devices/${this.props.activeDevice}/improvements`;
    //console.log(url);
    //console.log(this.state);
    //console.log(test);
    //console.log(selectedTest);
    //console.log(

    //);
    //console.log(response);
    let currentState = this.state;
    if (isNaN(currentState.level)) {
      currentState.level = this.handleInputChangeLevel(currentState.level);
    }
    try {
      if (validateClientPermission(this.props.clientPermission)) {
        await api.post(url, [
          currentState,
          selectedTest,
          selectedTestValve,
        ]);
        this.props.fetchDeviceImprovements(this.props.activeDevice);
      } else {
        //add local state addition here
        //TODO redux persistance
        // https://stackoverflow.com/questions/52161128/react-redux-state-is-lost-at-page-refresh
        // https://github.com/rt2zz/redux-persist
        console.log([currentState, selectedTest, selectedTestValve]);
        console.log(this.props.devices[this.props.activeDevice].improvements);
      }

      this.setState({
        status: '',
        statusMessage: '',
        open: false,
        formBool: false,
        improvement: '',
        comment: '',
        level: 1,
        improvementType: '',
        test: null,
        testDate: null,
        stateDate: null,
        editId: null,
        menus: {},
        approvalOpen: false,
        approvalName: '',
        approvalEmail: '',
        approvalId: null,
        approvalType: '',
        deleteId: null,
        deleteOpen: false,
      });
    } catch (e) {
      this.setState({
        status: 'Failure',
        statusMessage:
          !e.data || !e.data.error
            ? !e.statusText
              ? 'Failure!'
              : `Error: ${e.statusText}`
            : e.data.error,
        //loading: false,
      });
    }
  };
  componentDidMount() {
    if (
      !this.props.devices[this.props.activeDevice] ||
      !this.props.devices[this.props.activeDevice].improvements
    ) {
      this.props.fetchDevices(this.props.activeDevice);
      this.props.fetchDeviceImprovements(this.props.activeDevice);
      //this.props.fetchLoopEvents(this.props.activeLoop);
    }
  }
  componentDidUpdate() {
    let device = this.props.devices[this.props.activeDevice];
    let testId = !device ? null : device.activeProfileId;
    if (
      !!this.props.devices[this.props.activeDevice] &&
      !!this.props.devices[this.props.activeDevice].activeTestId &&
      !this.props.devices[this.props.activeDevice].issues &&
      !this.issueLoad
    ) {
      this.issueLoad = true;
      this.props.fetchDeviceIssues(
        this.props.activeDevice,
        this.props.devices[this.props.activeDevice].activeTestId
      );
      //this.props.fetchIssueDetails([1, 2, 3]);
      // this.props.fetchActions([1, 2, 3]);
      //this.props.fetchInvestigations([1, 2, 3]);
      //this.props.fetchLoopEvents(this.props.activeLoop);
    }
    if (
      (!this.props.tests_status ||
        !this.props.tests_status['diagnostics'] ||
        !(
          this.props.tests_status['diagnostics'][testId] === 'loading' ||
          this.props.tests_status['diagnostics'][testId] === 'loaded'
        )) &&
      !!testId
    ) {
      this.props.fetchDiagnostics(testId);
      this.props.fetchDeviceSpecs(this.props.activeDevice);
      this.props.setTestStatus('diagnostics', testId, 'loading');
    }
  }
*/
  orderIssuesValue(issue) {
    let i = issue.replace(this.props.activeLoop, '');
    //console.log(i);
    switch (i) {
      case 'Data Validity':
        return 1;
      case 'Stuck Device Values':
        return 2;
      case 'Low Data Resolution':
        return 3;
      case 'Saturated Valve':
        return 4;
      case 'Saturated Device':
        return 5;
      case 'Redundant Error':
        return 6;
      case 'Loop Error':
        return 7;
      case 'Control Range':
        return 8;
      case 'Trend Change':
        return 9;
      case 'Stability':
        return 10;
      case 'Volatility':
        return 11;
      case 'Oscillation Amplitude':
        return 12;
      case 'Signal Inconsistency':
        return 13;
      default:
        return 20;
    }
  }

  render() {
    const { classes } = this.props;
    const printStyles = functions.setPrintStyles(this.props.activeTab);
    const loop = this.props.loops[this.props.activeLoop];
    const orderedIssues =
      !loop || !loop.issues
        ? []
        : loop.issues.sort((a, b) => {
            return this.orderIssuesValue(a) - this.orderIssuesValue(b);
          });
    //const device = this.props.devices[this.props.activeDevice];
    return (
      <React.Fragment>
        {!loop || !loop.issues ? (
          <Paper className={printStyles ? classes.printPaper : classes.paper} elevation={3}>
            No Issues Found!
          </Paper>
        ) : (
          orderedIssues.map((issue, index) => {
            return this.renderIssue(issue, index);
          })
        )}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    activeLoop: state.prioritize.activeLoop,
    loops: state.loops.loops,
    devices: state.devices.devices,
    events: state.events,
    analysisRange: state.sort.filter.AnalysisRange,
    dataset: state.sort.filter.Dataset,
    actionRange: state.sort.filter.ActionRange,
    sort: state.sort,
    //devices: state.devices.devices,
    currentModule: state.currentModule.currentModule,
    userId: state.auth.userId,
    clientPermission: state.auth.clientPermission,
    //recommendations: state.recommendations,
    //tests: state.tests.tests,
    //tests_status: state.tests.status,
  };
};

export default connect(mapStateToProps, {
  /*fetchDeviceImprovements,
  fetchDeviceIssues,
  fetchActions,
  fetchInvestigations,
  fetchIssueDetails,
  fetchDevices,
  approveImprovement,
  fetchDeviceSpecs,
  fetchDiagnostics,
  setTestStatus,
  deleteImprovement,*/
  //
  fetchLoop,
  fetchLoopEvents,
  fetchLoopActionItems,
  //addImprovement /*, fetchDeviceEvents */,
  changeEventSelections,
})(withStyles(styles)(AccordionWidgetPrioritize));
