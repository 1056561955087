import api from './api.js';
import functions from '../functions/functions';

const getFastFieldAuth = async (count) => {
  let auth = functions.getCookie('fastFieldAuth');
  //console.log(auth);
  //console.log(auth);
  //auth = '';
  if (auth === '') {
    auth = requestFastFieldAuth();
  }
  return auth;
};

const requestFastFieldAuth = async (count) => {
  let currentCount = !count ? 0 : count;
  let auth = '';
  try {
    const authUrl = 'iba/getAuth';
    const response = await api.get(authUrl);
    console.log(response.status === 429);
    //let sessionExpiration = '2024-03-02T02:25:24.684Z';
    //let sessionToken = 'b2db0d0708f34b6d8f65e6e473254ea5';
    functions.setCookie('fastFieldAuth', response.data.sessionToken, 1 / 4 /*1 / 3 */, true); //expires in 6 hours (auth is valid for 8)
    auth = response.data.sessionToken;
    return auth;
  } catch (e) {
    //console.log(count, currentCount, currentCount + 1);
    if (currentCount < 2 && e.status === 429) {
      //allow 3 attempts to get auth
      setTimeout(function () {
        getFastFieldAuth(currentCount + 1);
        // Something you want delayed.
      }, 2000);
    }
    console.log(e);
  }
  return null;
};

const fastfield = { getFastFieldAuth, requestFastFieldAuth };

export default fastfield;
