import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { Typography, Grid, Paper, Tooltip, CircularProgress, IconButton } from '@mui/material';
import { Print } from '@mui/icons-material';
import { BuildCircle, Construction, Error, PowerOff, /*Replace,*/ Sync, Troubleshoot, Warning, KeyboardArrowUp, KeyboardArrowDown, Image } from '@mui/icons-material';
//import { Link } from 'react-router-dom';
import initializeTheme from '../theme.js';
import { connect } from 'react-redux';
import { styles } from '../styles.js';
//import TableComponent from '../charts/Table';
import functions from '../../functions/functions.js';
import ImageGallery from '../test/ImageGallery.js';
import WidgetTitle from '../headers/WidgetTitle.js';
import TextDisplay from '../filters/TextDisplay.js';
import getOlinTileHeaderArr from '../../arrays/fsr/olinTileHeaderArr.js';
import FastFieldImage from '../test/FastFieldImage.js';

class FSRTile extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      hasBorder: false,
      tileExpanded: false,
      selectMenu: false,
      valveTestMenu: false,
      anchor: null,
      checked: false,
    };
  }
  componentDidMount() {
    //componentDidUpdate() {

    if (this.props.hasBorder && this.setState.hasBorder) {
      this.setState({ hasBorder: true });
    }
    if (this.props.expanded && !this.setState.tileExpanded) {
      this.setState({ tileExpanded: true });
    }
  }

  handleTileExpand = (e) => {
    e.preventDefault();
    this.setState({
      tileExpanded: !this.state.tileExpanded,
    });
  };
  renderLoading() {
    const { classes } = this.props;
    if (this.state.loading) {
      return (
        <React.Fragment>
          <CircularProgress className={classes.loadIcon} size={15} color='secondary' />
          Loading...
        </React.Fragment>
      );
    } else {
      return;
    }
  }

  processImportance(importance) {
    switch (importance) {
      case 5:
        return 'Extremely Important';
      case 4:
        return 'Very Important';
      case 3:
        return 'Important';
      case 2:
        return 'Standard';
      case 1:
        return 'Not Important';
      default:
        return 'Standard';
    }
  }

  getGoogleMapLink = (device) => {
    if (!device.location_gps_lat || !device.location_gps_long) return null;
    let lat = device.location_gps_lat;
    //let latSuff = lat > 0 ? 'N' : 'S';
    let long = device.location_gps_long;
    let zoom = 14; //higher is closer
    //let longSuff = long > 0 ? 'E' : 'W';
    //return `https://maps.google.com/maps?width=100%25&height=600&hl=en&q=${lat},${long}+(My%20Name%20Here)&t=&z=14&ie=UTF8&iwloc=B&output=embed`;
    return `https://maps.google.com/maps?hl=en&q=${lat},${long}&z=${zoom}&ie=UTF8&iwloc=B&output=embed`;
  };

  itemDisplayFactory = (item, header, index, length) => {
    const theme = initializeTheme(this.props.colorMode);
    let device = !this.props.device ? {} : this.props.device; // !this.props.loops || !this.props.loops[this.props.match.params.id] ? {} : this.props.loops[this.props.match.params.id];
    //const { classes } = this.props;
    let color = !item.pass_values || item.pass_values.indexOf(device[item.id]) !== -1 ? null : theme.palette.red.color;
    //console.log(item, header, index, length);
    if (!(item.ignore_null === true && device[item.id] === null)) {
      if (item.type === 'image') {
        return <FastFieldImage key={`device_img_${item.id}`} file={device[item.id]} />;
      } else {
        return (
          <TextDisplay
            field={header !== 'Details'}
            last={index === length}
            id={null}
            key={`text_display_${item.label}`}
            label={item.label}
            color={color}
            text={!device[item.id] ? '- ' : device[item.id]}
            type='large'
          />
        );
      }
    }
  };

  getDeviceRecommendations = (device) => {
    //console.log(device);
    const { classes } = this.props;
    let list = [];
    /*if (device.meter_measurement_check === '0' && device.recommendation_type.indexOf('Replace') === -1) {
      list.push({ type: 'Measurement', text: 'Device type is incorrect for measurement value.' });
    }*/
    if (!!device.probe_broken && device.probe_broken.toLowerCase() !== 'no' && device.probe_broken.toLowerCase() !== '0') {
      list.push({ type: 'Broken', text: 'Probe was found Broken during service.' });
    }
    if (!!device.comment && device.comment.toLowerCase() !== 'no' && device.comment.toLowerCase() !== 'none') {
      list.push({ type: 'Comment', text: device.comment });
    }
    /*if (!!device.recommendations && device.recommendations !== '') {
      let recommendations = device.recommendations.split(','); //'Test 1||| where was my comma, Test 2'.split(','); //
      let recommendation_types = device.recommendation_type.split(',');
      for (let i = 0; i < recommendations.length; i++) {
        let recommendationsComma = recommendations[i].replace('|||', ',');
        list.push({ type: recommendation_types[i], text: recommendationsComma });
      }
    }*/
    let defaultColors = ['#E19878', '#598FD6', '#C866A7', '#48959B', '#9473C5', '#CEAD9F', '#88A5C9', '#CB8A9F', '#84AFBB', '#A796C1'];
    let backgroundColors = [
      'rgba(225, 152, 120, 0.1)',
      'rgba(89, 143, 214, 0.1)',
      'rgba(200, 102, 167, 0.1)',
      'rgba(72, 149, 155, 0.1)',
      'rgba(148, 115, 197, 0.1)',
      'rgba(206, 173, 159, 0.1)',
      'rgba(136, 165, 201, 0.1)',
      'rgba(203, 138, 159, 0.1)',
      'rgba(132, 175, 187, 0.1)',
      'rgba(167, 150, 193, 0.1)',
    ];
    //console.log(list);
    return (
      <React.Fragment>
        {list.map((item, index) => {
          let icon = null;
          let color = '#666';
          let backgroundColor = this.props.colorMode === 'dark' ? '#666' : '#EEE';
          switch (item.type) {
            case 'Broken': //'Error':
              color = defaultColors[0];
              backgroundColor = backgroundColors[0];
              icon = <Error className={classes.alertIcon} sx={{ color: color }} />;
              break;
            case 'Comment': //'Warning':
              color = defaultColors[1];
              backgroundColor = backgroundColors[1];
              icon = <Warning className={classes.alertIcon} sx={{ color: color }} />;
              break;
            case 'Off':
              color = defaultColors[1];
              backgroundColor = backgroundColors[1];
              icon = <PowerOff className={classes.alertIcon} sx={{ color: color }} />;
              break;
            case 'Measurement':
              color = defaultColors[1];
              backgroundColor = backgroundColors[1];
              icon = <Sync className={classes.alertIcon} sx={{ color: color }} />;
              break;
            case 'Calibration':
              color = defaultColors[2];
              backgroundColor = backgroundColors[2];
              icon = <BuildCircle className={classes.alertIcon} sx={{ color: color }} />;
              break;
            case 'Repair':
              color = defaultColors[3];
              backgroundColor = backgroundColors[3];
              icon = <Construction className={classes.alertIcon} sx={{ color: color }} />;
              break;
            case 'Replace':
              color = defaultColors[4];
              backgroundColor = backgroundColors[4];
              icon = <Sync className={classes.alertIcon} sx={{ color: color }} />;
              break;
            case 'Troubleshoot':
              color = defaultColors[5];
              backgroundColor = backgroundColors[5];
              icon = <Troubleshoot className={classes.alertIcon} sx={{ color: color }} />;
              break;
            default:
              color = defaultColors[6];
              backgroundColor = backgroundColors[6];
              icon = <Warning className={classes.alertIcon} sx={{ color: color }} />;
          }
          return (
            <Grid key={`recommendation_${index}`} style={{ marginBottom: 4 }}>
              <div className={classes.alert} style={{ borderLeft: '8px solid ' + color, borderColor: color, backgroundColor: backgroundColor }} elevation={3}>
                <Typography>
                  {icon} {item.type} - {item.text}
                </Typography>
              </div>
            </Grid>
          );
          /*return (
            <Alert key={`alert_${item.text}`} severity={item.type} style={{ marginBottom: 1 }}>
              {item.text}
            </Alert>
          );*/
        })}
      </React.Fragment>
    );
  };

  renderExpandedTile() {
    const { classes } = this.props;
    const device = !this.props.device ? {} : this.props.device;
    let headerArr = [];

    if (this.props.type === 'Olin') {
      headerArr = getOlinTileHeaderArr();
      if (device.turned_away === 1) {
        headerArr = getOlinTileHeaderArr('turned_away');
      }
    } else {
      headerArr = [
        {
          headerName: 'Details',
          //open: true,
          //headerNote: '',
          sub_items: [
            { id: 'technician', label: 'Technician' },
            { id: 'serial', label: 'Serial' },
            { id: 'as_found_value_rounded_pH', label: 'As Found (pH)' },
            { id: 'as_left_value_rounded_pH', label: 'As Left (pH)' },
            { id: 'change_value_rounded_pH', label: 'Value Change (pH)' },
            { id: 'as_found_value_rounded_orp', label: 'As Found (ORP)' },
            { id: 'as_left_value_rounded_orp', label: 'As Left (ORP)' },
            { id: 'change_value_rounded_orp', label: 'Value Change (ORP)' },
            { id: 'further_repair', label: 'Additional Repair Required' },
            { id: 'customer_notified', label: 'Was Customer Notified?' },
            { id: 'person_notified', label: 'Person Notified' },

            //{ id: '', label: '' },
            //{ id: '', label: '' },
            //{ id: '', label: '' },
          ],
        },
        { headerName: 'Status', id: 'asset_photos' },
        /*{
        headerName: 'Details 2',
        sub_items: [
          { id: 'as_found_value_rounded_pH', label: 'As Found (pH)' },
          { id: 'as_left_value_rounded_pH', label: 'As Left (pH)' },
        ],
      },*/
        { headerName: 'Images', id: 'multi_photo_picker_1_photo' },
      ];
    }

    let googleMapLink = this.getGoogleMapLink(device);
    //const printStyles = functions.setPrintStyles(this.props.activeTab);
    //console.log(device);
    let dataArr = [];
    //console.log(!loop ? 'Not Ready' : loop);
    if (!!device && !!device.device_ids) {
      for (let i = 0; i < device.device_ids.length; i++) {
        let device = this.props.devices[parseInt(this.props.device.device_ids[i])];
        //console.log(this.props.devices);
        if (!!device && !!device.processName) {
          dataArr.push({
            id: device.device_id,
            deviceId: device.name,
            deviceType: (
              <React.Fragment>
                <Typography>
                  {`${device.deviceType}`}
                  {/*!device.optimize_id ? null : (
                    <InfoPopover
                      center
                      tooltip='Latest Optimize Test'
                      title='Latest Optimize Test'
                      titleIcon='Speed'
                      resize
                      style={{ marginBottom: -15 }}
                      icon={<Speed />}
                      content={<OptimizeTile hasLink={!printStyles} hasBorder key={`${this.props.devices[device.device_id]}_optimize_test`} device={device} prioritize />}
                    />
                  )*/}
                </Typography>
              </React.Fragment>
            ),
            description: !device.description ? null : device.description.length > 12 && !this.props.format ? (
              <Tooltip arrow placement='top' title={device.description} enterDelay={300} style={{ cursor: 'pointer' }}>
                <span>{device.description.substring(0, 12)} ...</span>
              </Tooltip>
            ) : (
              device.description
            ),
            concernScore: device.concernAdjusted,
            concernShift: Math.round(device.concernShift),
            concernMax: Math.round(device.concernMax),
            importance: device.importance,
          });
        }
      }
    }
    const printStyles = functions.setPrintStyles(this.props.activeTab);
    if (this.state.tileExpanded || this.props.alwaysExpanded) {
      return (
        <Grid item xs={12} style={printStyles ? null : { padding: 16 }}>
          {headerArr.map((section, index) => {
            let header = section.headerName;
            if (section.headerName === 'Status') {
              return (
                <React.Fragment key={`section_${section.headerName}_${index}`}>
                  {!device || !device.status || (device.status === 'No Issue' && (device.comment === 'No' || device.comment === 'None')) ? null : (
                    <React.Fragment>
                      <WidgetTitle title={`Status`} />
                      {!device || !device.status ? null : this.getDeviceRecommendations(device)}
                    </React.Fragment>
                  )}
                </React.Fragment>
              );
            }
            if (section.headerName === 'Images') {
              return (
                <React.Fragment key={`section_${section.headerName}_${index}`}>
                  {!device[section.id] || device[section.id].length === 0 ? null : (
                    <React.Fragment>
                      <WidgetTitle title={`Images`} />
                      <Typography variant='body2' style={{ marginBottom: 4 }} className={classes.printHide}>
                        Select an image to open
                      </Typography>
                      <ImageGallery tag={!device.tag || device.tag === 'Unknown' ? `Unknown Location` : `${device.tag}`} images={device[section.id]} />
                    </React.Fragment>
                  )}
                </React.Fragment>
              );
            }
            if (section.headerName === 'Map') {
              return (
                <Grid item key={`section_${section.headerName}_${index}`} style={printStyles ? { marginBottom: 16 } : null}>
                  {!googleMapLink ? null : (
                    <React.Fragment>
                      <WidgetTitle title={`Map`} />
                      <iframe
                        title={`Google Map for Device ${device.tag}`}
                        width='100%'
                        height='600'
                        frameBorder='0'
                        marginHeight='0'
                        marginWidth='0'
                        src={googleMapLink}
                        style={{ marginBottom: -8, borderRadius: 4 }}
                      />
                    </React.Fragment>
                  )}
                </Grid>
              );
            }
            return (
              <Grid item key={`section_${section.headerName}_${index}`} className={classes.avoidBreak} style={printStyles ? { marginBottom: 16 } : null}>
                <Grid item>
                  <WidgetTitle title={`${section.headerName}`} />
                  <Grid container spacing={header === 'Details' ? 4 : 0}>
                    {section.sub_items.map((item, index) => {
                      let length = section.sub_items.length - 1;
                      return this.itemDisplayFactory(item, header, index, length);
                    })}
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      );
    } else {
      return null;
    }
  }

  clickPrint(id) {
    let win = window.open(`${window.location.href}/detail/${id}/print`, '_blank');
    win.focus();
  }

  render() {
    const theme = initializeTheme(this.props.colorMode);
    const { classes } = this.props;
    const device = !this.props.device ? {} : this.props.device;
    const printStyles = functions.setPrintStyles(this.props.activeTab);
    let content = <div>Could not find data for this loop!</div>;
    let styleObj = Object.assign({}, device.style, { marginBottom: this.props.colorMode === 'dark' ? 6 : 4 });
    if (this.props.device && printStyles) {
      content = (
        <React.Fragment>
          <Paper className={`${printStyles ? classes.printFSRTile : classes.ibaTile}`} style={styleObj} elevation={3}>
            <Grid
              container
              justifyContent='space-between'
              onClick={this.handleTileExpand}
              className={printStyles ? null : !this.state.tileExpanded || this.props.alwaysExpanded ? classes.ibaTileHeader : classes.ibaTileHeaderExpanded}
            >
              <Grid item xs={12} sm={12} md={12}>
                <Grid container justifyContent='space-between'>
                  <Grid item sm={6} md={6}>
                    <Typography variant='subtitle2'>
                      <span style={theme.palette.blue}>{!device.utcTime ? 'Unknown Date' : functions.dateToString(device.utcTime, 'date')}</span>
                      {!device.service_type ? ' - Unknown Service Type' : ` - ${device.service_type}`}
                    </Typography>
                  </Grid>
                  <Grid item sm={6} md={6}>
                    {this.props.type === 'Olin' ? (
                      <Typography variant='subtitle2'>
                        {!device.site ? 'Site Not Found' : `${device.site}`}
                        {!device.application ? ' - Customer Not Found' : ` - ${device.application} `}
                      </Typography>
                    ) : (
                      <Typography variant='subtitle2'>
                        {!device.customer ? 'Customer Not Found - ' : `${device.customer} - `}
                        {!device.site ? 'Site Not Found' : `${device.site}`}
                      </Typography>
                    )}
                  </Grid>
                  {/*<Grid item sm={10} md={4} lg={2}>
                  <Typography variant='subtitle2'>
                    {!device.tag ? null : 'Device: '}
                    <span style={theme.palette.blue}>
                      {!device.tag ? 'No Device Exists' : device.tag}
                      {
                      //!device.devices || device.devices === 'undefined' ? 'Unknown Devices' : device.devices.length} Device{!device.devices || device.devices.length === 1 ? '' : 's'
                      }
                      {!device.asset_type || device.asset_type === 'undefined' ? null : ` - ${device.asset_type}`}
                    </span>
                    {!device.manufacturer || device.manufacturer === 'undefined' ? null : ` - ${device.manufacturer}`}
                  </Typography>
                </Grid>*/}
                  <Grid item sm={6} md={6}>
                    {this.props.type === 'Olin' ? (
                      <Typography variant='subtitle2'>
                        {!device.tag || device.tag === 'Unknown' ? null : 'Tag: '}
                        <span style={theme.palette.blue}>{!device.tag || device.tag === 'Unknown' ? `Unknown Tag` : `${device.tag}`}</span>
                        {!device.analyzer_type || device.application === 'undefined' ? null : ` - ${device.analyzer_type}`}
                      </Typography>
                    ) : (
                      <Typography variant='subtitle2'>
                        {!device.tag || device.tag === 'Unknown' ? null : 'Tag: '}
                        <span style={theme.palette.blue}>{!device.tag || device.tag === 'Unknown' ? `Unknown Tag` : `${device.tag}`}</span>
                        {!device.application || device.application === 'undefined' ? null : ` - ${device.application}`}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item sm={6} md={6}>
                    <Typography variant='subtitle2'>
                      {(!!device.asset_photos && device.asset_photos.length > 0) || (!!device.multi_photo_picker_1_photo && device.multi_photo_picker_1_photo.length > 0) ? (
                        <Tooltip arrow placement='top' title={'This report contains images'} enterDelay={300} style={{ cursor: 'pointer' }}>
                          <Image className={classes.alertIcon} />
                        </Tooltip>
                      ) : (
                        ''
                      )}
                      {!device.status ? 'Okay' : device.status}
                    </Typography>
                  </Grid>
                  {this.props.type === 'Olin' ? (
                    <React.Fragment>
                      <Grid item sm={6} md={6}>
                        <Typography variant='subtitle2'>
                          {!device.field_measurement_af ? null : 'As Found: '}
                          <span style={theme.palette.blue}>{!device.field_measurement_af ? null : device.field_measurement_af}</span>
                        </Typography>
                      </Grid>
                      <Grid item sm={6} md={6}>
                        <Typography variant='subtitle2'>
                          {!device.field_measurement_al ? null : 'As Left: '}
                          <span style={theme.palette.blue}>{!device.field_measurement_al ? null : device.field_measurement_al}</span>
                        </Typography>
                      </Grid>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Grid item sm={6} md={6}>
                        <Typography variant='subtitle2'>
                          {!device.change_value_rounded_pH ? null : 'pH Change: '}
                          <span style={theme.palette.blue}>{!device.change_value_rounded_pH ? null : device.change_value_rounded_pH}</span>
                        </Typography>
                      </Grid>
                      <Grid item sm={6} md={6}>
                        <Typography variant='subtitle2'>
                          {!device.change_value_rounded_orp ? null : 'ORP Change: '}
                          <span style={theme.palette.blue}>{!device.change_value_rounded_orp ? null : device.change_value_rounded_orp}</span>
                        </Typography>
                      </Grid>
                    </React.Fragment>
                  )}
                  {this.props.alwaysExpanded ? null : (
                    <React.Fragment>
                      <Grid item className={classes.printHide}>
                        <IconButton style={{ padding: 0, marginTop: -4 }} color='inherit' onClick={() => this.clickPrint(device.deviceId)} size='large'>
                          <Print></Print>
                        </IconButton>
                      </Grid>
                      <Grid item>{!this.state.tileExpanded || this.props.alwaysExpanded ? <KeyboardArrowDown></KeyboardArrowDown> : <KeyboardArrowUp></KeyboardArrowUp>}</Grid>
                    </React.Fragment>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Paper>
          <Grid container>{this.renderExpandedTile()}</Grid>
        </React.Fragment>
      );
    } else if (this.props.device) {
      content = (
        <Paper className={`${printStyles ? null : classes.ibaTile}`} style={styleObj} elevation={3}>
          <Grid
            container
            justifyContent='space-between'
            onClick={this.handleTileExpand}
            className={printStyles ? null : !this.state.tileExpanded || this.props.alwaysExpanded ? classes.ibaTileHeader : classes.ibaTileHeaderExpanded}
          >
            <Grid item xs={12} sm={12} md={12}>
              <Grid container justifyContent='space-between'>
                <Grid item xs={12} sm={6} md={6} lg={2}>
                  <Typography variant='subtitle2'>
                    <span style={theme.palette.blue}>{!device.utcTime ? 'Unknown Date' : functions.dateToString(device.utcTime, 'date')}</span>
                    {!device.service_type ? ' - Unknown Service Type' : ` - ${device.service_type}`}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={2}>
                  {this.props.type === 'Olin' ? (
                    <Typography variant='subtitle2'>
                      {!device.site ? 'Site Not Found' : `${device.site}`}
                      {!device.application ? ' - Customer Not Found' : ` - ${device.application} `}
                    </Typography>
                  ) : (
                    <Typography variant='subtitle2'>
                      {!device.customer ? 'Customer Not Found - ' : `${device.customer} - `}
                      {!device.site ? 'Site Not Found' : `${device.site}`}
                    </Typography>
                  )}
                </Grid>
                {/*<Grid item sm={10} md={4} lg={2}>
                  <Typography variant='subtitle2'>
                    {!device.tag ? null : 'Device: '}
                    <span style={theme.palette.blue}>
                      {!device.tag ? 'No Device Exists' : device.tag}
                      {
                      //!device.devices || device.devices === 'undefined' ? 'Unknown Devices' : device.devices.length} Device{!device.devices || device.devices.length === 1 ? '' : 's'
                      }
                      {!device.asset_type || device.asset_type === 'undefined' ? null : ` - ${device.asset_type}`}
                    </span>
                    {!device.manufacturer || device.manufacturer === 'undefined' ? null : ` - ${device.manufacturer}`}
                  </Typography>
                </Grid>*/}
                <Grid item xs={12} sm={6} md={6} lg={2}>
                  {this.props.type === 'Olin' ? (
                    <Typography variant='subtitle2'>
                      {!device.tag || device.tag === 'Unknown' ? null : 'Tag: '}
                      <span style={theme.palette.blue}>{!device.tag || device.tag === 'Unknown' ? `Unknown Tag` : `${device.tag}`}</span>
                      {!device.analyzer_type || device.application === 'undefined' ? null : ` - ${device.analyzer_type}`}
                    </Typography>
                  ) : (
                    <Typography variant='subtitle2'>
                      {!device.tag || device.tag === 'Unknown' ? null : 'Tag: '}
                      <span style={theme.palette.blue}>{!device.tag || device.tag === 'Unknown' ? `Unknown Tag` : `${device.tag}`}</span>
                      {!device.application || device.application === 'undefined' ? null : ` - ${device.application}`}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={2}>
                  <Typography variant='subtitle2'>
                    {(!!device.asset_photos && device.asset_photos.length > 0) || (!!device.multi_photo_picker_1_photo && device.multi_photo_picker_1_photo.length > 0) ? (
                      <Tooltip arrow placement='top' title={'This report contains images'} enterDelay={300} style={{ cursor: 'pointer' }}>
                        <Image className={classes.alertIcon} />
                      </Tooltip>
                    ) : (
                      ''
                    )}
                    {!device.status ? 'Okay' : device.status}
                  </Typography>
                </Grid>
                {this.props.type === 'Olin' ? (
                  <React.Fragment>
                    <Grid item xs={12} sm={6} md={6} lg={1}>
                      <Typography variant='subtitle2'>
                        {!device.field_measurement_af ? null : 'As Found: '}
                        <span style={theme.palette.blue}>{!device.field_measurement_af ? null : device.field_measurement_af}</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={1}>
                      <Typography variant='subtitle2'>
                        {!device.field_measurement_al ? null : 'As Left: '}
                        <span style={theme.palette.blue}>{!device.field_measurement_al ? null : device.field_measurement_al}</span>
                      </Typography>
                    </Grid>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Grid item xs={12} sm={6} md={6} lg={1}>
                      <Typography variant='subtitle2'>
                        {!device.change_value_rounded_pH ? null : 'pH Change: '}
                        <span style={theme.palette.blue}>{!device.change_value_rounded_pH ? null : device.change_value_rounded_pH}</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={1}>
                      <Typography variant='subtitle2'>
                        {!device.change_value_rounded_orp ? null : 'ORP Change: '}
                        <span style={theme.palette.blue}>{!device.change_value_rounded_orp ? null : device.change_value_rounded_orp}</span>
                      </Typography>
                    </Grid>
                  </React.Fragment>
                )}
                {this.props.alwaysExpanded ? null : (
                  <React.Fragment>
                    <Grid item className={classes.printHide}>
                      <IconButton style={{ padding: 0, marginTop: -4 }} color='inherit' onClick={() => this.clickPrint(device.deviceId)} size='large'>
                        <Print></Print>
                      </IconButton>
                    </Grid>
                    <Grid item>{!this.state.tileExpanded || this.props.alwaysExpanded ? <KeyboardArrowDown></KeyboardArrowDown> : <KeyboardArrowUp></KeyboardArrowUp>}</Grid>
                  </React.Fragment>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid container>{this.renderExpandedTile()}</Grid>
        </Paper>
      );
    }
    return (
      <Grid item xs={12} sm={12} md={!this.state.tileExpanded ? 6 : 12} lg={!this.state.tileExpanded ? 4 : 12} className={classes.textDecoNone}>
        {content}
      </Grid>
    );
  }
}

FSRTile.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
    userId: state.auth.userId,
    locations: state.locations,
    errors: state.errors,
    events: state.events,
    loops: state.loops.loops,
    devices: state.devices.devices,
    activeLoop: state.prioritize.activeLoop,
    analysisRange: state.sort.filter.AnalysisRange,
    colorMode: state.colorMode.colorMode,
    format: state.format.format,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(FSRTile));
