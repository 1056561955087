const issuesArray = [
  {
    title: 'Concern',
    issues: [
      {
        varTitle: 'Concern Score',
        subtitle: 'Concern Score',
        issue: 'ConcernScore',
      },
    ],
  },
  {
    title: 'Data Quality',
    issues: [
      { varTitle: 'Validity', subtitle: 'Validity', issue: 'Validity' },
      { varTitle: 'Stuck Reading', subtitle: 'Stuck Reading', issue: 'Stuck' },
      {
        varTitle: 'Data Resolution',
        subtitle: 'Data Resolution',
        issue: 'Quantization',
      },
    ],
  },
  {
    title: 'Profit Limiting Conditions',
    issues: [
      { varTitle: 'Saturation', subtitle: 'Saturation', issue: 'Saturation' },
      { varTitle: 'Loop Error', subtitle: 'Loop Error', issue: 'LoopErr' },
      {
        varTitle: 'Redundant Error',
        subtitle: 'Redundant Error',
        issue: 'RedundantErr',
      },
      {
        varTitle: 'Control Range',
        subtitle: 'Control Range',
        issue: 'ControlRange',
      },
      {
        varTitle: 'Trend Change',
        subtitle: 'Trend Change',
        issue: 'TrendChange',
      },
      { varTitle: 'Stability', subtitle: 'Stability', issue: 'Stability' },
      { varTitle: 'Volatility', subtitle: 'Volatility', issue: 'Volatility' },
      {
        varTitle: 'Oscillation Amplitude',
        subtitle: 'Oscillation Amplitude',
        issue: 'OscillationAmp',
      },
      /*{
        varTitle: 'Signal Inconsistency',
        subtitle: 'Signal Inconsistency',
        issue: 'SignalInconsistency',
      },*/
      {
        varTitle: 'Process Downtime',
        subtitle: 'Process Downtime',
        issue: 'Downtime',
      },
    ],
  },
];

export default issuesArray;
