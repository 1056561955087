import actBalEx1 from '../../images/screenshots_helpers/actBalEx1.JPG';
import actBalEx2 from '../../images/screenshots_helpers/actBalEx2.JPG';
import actBlockageEx1 from '../../images/screenshots_helpers/actBlockageEx1.jpg';
import actBlockageEx2 from '../../images/screenshots_helpers/actBlockageEx2.jpg';
import actBlockageEx3 from '../../images/screenshots_helpers/actBlockageEx3.jpg';
import actBlockageEx4 from '../../images/screenshots_helpers/actBlockageEx4.jpg';
import actEx1 from '../../images/screenshots_helpers/actEx1.JPG';
import actEx2 from '../../images/screenshots_helpers/actEx2.JPG';
import actEx3 from '../../images/screenshots_helpers/actEx3.JPG';
import actEx4 from '../../images/screenshots_helpers/actEx4.JPG';
import actEx5 from '../../images/screenshots_helpers/actEx5.JPG';
import actFlowEx1 from '../../images/screenshots_helpers/actFlowEx1.jpg';
import actFlowEx2 from '../../images/screenshots_helpers/actFlowEx2.jpg';
import actLeakEx1 from '../../images/screenshots_helpers/actLeakEx1.jpg';
import actLeakEx2 from '../../images/screenshots_helpers/actLeakEx2.jpg';
import actLeakEx3 from '../../images/screenshots_helpers/actLeakEx3.jpg';
import actLeakEx4 from '../../images/screenshots_helpers/actLeakEx4.jpg';
import actLeakEx5 from '../../images/screenshots_helpers/actLeakEx5.jpg';
import actLeakEx6 from '../../images/screenshots_helpers/actLeakEx6.jpg';
import actLeakEx7 from '../../images/screenshots_helpers/actLeakEx7.jpg';
import airLeakEx1 from '../../images/screenshots_helpers/airLeakEx1.jpg';
import airLeakEx2 from '../../images/screenshots_helpers/airLeakEx2.jpg';
import airLeakEx3 from '../../images/screenshots_helpers/airLeakEx3.jpg';
import airLeakEx4 from '../../images/screenshots_helpers/airLeakEx4.jpg';
import airLeakEx5 from '../../images/screenshots_helpers/airLeakEx5.jpg';
import airLeakEx6 from '../../images/screenshots_helpers/airLeakEx6.jpg';
import actOverpressureEx1 from '../../images/screenshots_helpers/actOverpressureEx1.JPG';
import actOverpressureEx2 from '../../images/screenshots_helpers/actOverpressureEx2.JPG';
import actPressureEx1 from '../../images/screenshots_helpers/actPressureEx1.JPG';
import actPressureEx2 from '../../images/screenshots_helpers/actPressureEx2.JPG';
import actSatEx1 from '../../images/screenshots_helpers/actSatEx1.JPG';
import actSatEx2 from '../../images/screenshots_helpers/actSatEx2.jpg';
import actSatEx3 from '../../images/screenshots_helpers/actSatEx3.jpg';
import actSatEx4 from '../../images/screenshots_helpers/actSatEx4.jpg';
import actTravelEx1 from '../../images/screenshots_helpers/actTravelEx1.JPG';
import actTravelEx2 from '../../images/screenshots_helpers/actTravelEx2.JPG';
import actVolumeEx1 from '../../images/screenshots_helpers/actVolumeEx1.jpg';
import actVolumeEx2 from '../../images/screenshots_helpers/actVolumeEx2.jpg';
import actVolumeEx3 from '../../images/screenshots_helpers/actVolumeEx3.jpg';
import airDroopEx1 from '../../images/screenshots_helpers/airDroopEx1.JPG';
import airDroopEx2 from '../../images/screenshots_helpers/airDroopEx2.JPG';
import airDroopEx3 from '../../images/screenshots_helpers/airDroopEx3.JPG';
import airDroopLengthEx1 from '../../images/screenshots_helpers/airDroopLengthEx1.JPG';
import airEx1 from '../../images/screenshots_helpers/airEx1.JPG';
import airEx2 from '../../images/screenshots_helpers/airEx2.JPG';
import airEx3 from '../../images/screenshots_helpers/airEx3.JPG';
import airEx4 from '../../images/screenshots_helpers/airEx4.JPG';
import airEx5 from '../../images/screenshots_helpers/airEx5.JPG';
import airEx6 from '../../images/screenshots_helpers/airEx6.JPG';
import airEx7 from '../../images/screenshots_helpers/airEx7.JPG';
import airEx8 from '../../images/screenshots_helpers/airEx8.JPG';
import airEx9 from '../../images/screenshots_helpers/airEx9.JPG';
import airActionEx1 from '../../images/screenshots_helpers/airActionEx1.jpg';
import airActionEx2 from '../../images/screenshots_helpers/airActionEx2.jpg';
import airActionEx3 from '../../images/screenshots_helpers/airActionEx3.jpg';
import failActionEx1 from '../../images/screenshots_helpers/failActionEx1.jpg';
import failActionEx2 from '../../images/screenshots_helpers/failActionEx2.jpg';
import failActionEx3 from '../../images/screenshots_helpers/failActionEx3.jpg';
import airLevelsEx1 from '../../images/screenshots_helpers/airLevelsEx1.JPG';
import airLevelsEx2 from '../../images/screenshots_helpers/airLevelsEx2.JPG';
import airNoiseEx1 from '../../images/screenshots_helpers/airNoiseEx1.JPG';
import airNoiseEx2 from '../../images/screenshots_helpers/airNoiseEx2.JPG';
import airNoiseEx3 from '../../images/screenshots_helpers/airNoiseEx3.jpg';
import benchSetEx1 from '../../images/screenshots_helpers/benchSetEx1.JPG';
import benchSetEx2 from '../../images/screenshots_helpers/benchSetEx2.JPG';
import biasEx1 from '../../images/screenshots_helpers/biasEx1.JPG';
import biasEx2 from '../../images/screenshots_helpers/biasEx2.JPG';
import calibrationEx1 from '../../images/screenshots_helpers/calibrationEx1.JPG';
import calibrationEx2 from '../../images/screenshots_helpers/calibrationEx2.JPG';
import camEx1 from '../../images/screenshots_helpers/camEx1.jpg';
import crossoverEx1 from '../../images/screenshots_helpers/crossoverEx1.JPG';
import crossoverEx2 from '../../images/screenshots_helpers/crossoverEx2.JPG';
import cutoffEx1 from '../../images/screenshots_helpers/cutoffEx1.jpg';
import deadbandEx1 from '../../images/screenshots_helpers/deadbandEx1.jpg';
import deadbandEx2 from '../../images/screenshots_helpers/deadbandEx2.jpg';
import dynErrorEx1 from '../../images/screenshots_helpers/dynErrorEx1.JPG';
import frictionEx1 from '../../images/screenshots_helpers/frictionEx1.JPG';
import frictionEx2 from '../../images/screenshots_helpers/frictionEx2.JPG';
import frictionEx3 from '../../images/screenshots_helpers/frictionEx3.JPG';
import frictionLowEx1 from '../../images/screenshots_helpers/frictionLowEx1.JPG';
import frictionLowEx2 from '../../images/screenshots_helpers/frictionLowEx2.JPG';
import frictionLowEx3 from '../../images/screenshots_helpers/frictionLowEx3.JPG';
import frictionGraphEx2 from '../../images/screenshots_helpers/frictionGraphEx2.jpg';
import frictionGraphEx3 from '../../images/screenshots_helpers/frictionGraphEx3.jpg';
import frictionGraphEx4 from '../../images/screenshots_helpers/frictionGraphEx4.jpg';
import frictionMedianEx1 from '../../images/screenshots_helpers/frictionMedianEx1.jpg';
import frictionMedianEx2 from '../../images/screenshots_helpers/frictionMedianEx2.jpg';
import frictionMedianEx3 from '../../images/screenshots_helpers/frictionMedianEx3.jpg';
import frictionNoiseEx1 from '../../images/screenshots_helpers/frictionNoiseEx1.JPG';
import frictionNoiseEx2 from '../../images/screenshots_helpers/frictionNoiseEx2.JPG';
import frictionNoiseEx3 from '../../images/screenshots_helpers/frictionNoiseEx3.JPG';
import frictionNoiseEx4 from '../../images/screenshots_helpers/frictionNoiseEx4.JPG';
import frictionShapeEx1 from '../../images/screenshots_helpers/frictionShapeEx1.JPG';
import frictionShapeEx2 from '../../images/screenshots_helpers/frictionShapeEx2.JPG';
import frictionShapeEx3 from '../../images/screenshots_helpers/frictionShapeEx3.JPG';
import frictionShapeEx4 from '../../images/screenshots_helpers/frictionShapeEx4.jpg';
import frictionStaticEx1 from '../../images/screenshots_helpers/frictionStaticEx1.jpg';
import frictionStaticEx2 from '../../images/screenshots_helpers/frictionStaticEx2.jpg';
import frictionValEx1 from '../../images/screenshots_helpers/frictionValEx1.JPG';
import frictionValEx2 from '../../images/screenshots_helpers/frictionValEx2.JPG';
import frictionValEx3 from '../../images/screenshots_helpers/frictionValEx3.JPG';
import gallingEx1 from '../../images/screenshots_helpers/gallingEx1.jpg';
import gallingEx2 from '../../images/screenshots_helpers/gallingEx2.jpg';
import gallingEx3 from '../../images/screenshots_helpers/gallingEx3.jpg';
import gallingEx4 from '../../images/screenshots_helpers/gallingEx4.jpg';
import gallingEx5 from '../../images/screenshots_helpers/gallingEx5.jpg';
import gallingEx6 from '../../images/screenshots_helpers/gallingEx6.jpg';
import gallingEx7 from '../../images/screenshots_helpers/gallingEx7.jpg';
import gallingEx8 from '../../images/screenshots_helpers/gallingEx8.jpg';
import inadequateValveEx1 from '../../images/screenshots_helpers/inadequateValveEx1.jpg';
import inadequateValveEx2 from '../../images/screenshots_helpers/inadequateValveEx2.jpg';
import inadequateValveEx3 from '../../images/screenshots_helpers/inadequateValveEx3.jpg';
import ipEx1 from '../../images/screenshots_helpers/ipEx1.JPG';
import ipEx2 from '../../images/screenshots_helpers/ipEx2.JPG';
import ipEx3 from '../../images/screenshots_helpers/ipEx3.JPG';
import ipEx4 from '../../images/screenshots_helpers/ipEx4.JPG';
import ipEx5 from '../../images/screenshots_helpers/ipEx5.JPG';
import ipEx6 from '../../images/screenshots_helpers/ipEx6.JPG';
import ipTravelEx1 from '../../images/screenshots_helpers/ipTravelEx1.jpg';
import ipErrorEx1 from '../../images/screenshots_helpers/ipErrorEx1.jpg';
import ipErrorEx2 from '../../images/screenshots_helpers/ipErrorEx2.jpg';
import lagEx1 from '../../images/screenshots_helpers/lagEx1.jpg';
import lagEx2 from '../../images/screenshots_helpers/lagEx2.jpg';
import linearEx1 from '../../images/screenshots_helpers/linearEx1.JPG';
import nomTravelEx1 from '../../images/screenshots_helpers/nomTravelEx1.jpg';
import overshootEx1 from '../../images/screenshots_helpers/overshootEx1.jpg';
import overshootEx2 from '../../images/screenshots_helpers/overshootEx2.jpg';
import overshootEx3 from '../../images/screenshots_helpers/overshootEx3.jpg';
import resEx1 from '../../images/screenshots_helpers/resEx1.JPG';
import resEx2 from '../../images/screenshots_helpers/resEx2.JPG';
import resEx3 from '../../images/screenshots_helpers/resEx3.jpg';
import resEx4 from '../../images/screenshots_helpers/resEx4.jpg';
import seatEntryEx1 from '../../images/screenshots_helpers/seatEntryEx1.JPG';
import seatEntryEx2 from '../../images/screenshots_helpers/seatEntryEx2.JPG';
import seatEx1 from '../../images/screenshots_helpers/seatEx1.JPG';
import seatEx2 from '../../images/screenshots_helpers/seatEx2.JPG';
import seatEx3 from '../../images/screenshots_helpers/seatEx3.JPG';
import seatEx4 from '../../images/screenshots_helpers/seatEx4.JPG';
import seatEx5 from '../../images/screenshots_helpers/seatEx5.JPG';
import seatEx6 from '../../images/screenshots_helpers/seatEx6.JPG';
import seatExitEx1 from '../../images/screenshots_helpers/seatExitEx1.JPG';
import seatExitEx2 from '../../images/screenshots_helpers/seatExitEx2.JPG';
import senEx1 from '../../images/screenshots_helpers/senEx1.JPG';
import senEx2 from '../../images/screenshots_helpers/senEx2.JPG';
import senEx3 from '../../images/screenshots_helpers/senEx3.JPG';
import senEx4 from '../../images/screenshots_helpers/senEx4.JPG';
import senEx5 from '../../images/screenshots_helpers/senEx5.jpg';
import signalSpanEx1 from '../../images/screenshots_helpers/signalSpanEx1.jpg';
import stepTimesEx1 from '../../images/screenshots_helpers/stepTimesEx1.jpg';
import stepTimesEx2 from '../../images/screenshots_helpers/stepTimesEx2.jpg';
import stepTimesEx3 from '../../images/screenshots_helpers/stepTimesEx3.jpg';
import strokeEx1 from '../../images/screenshots_helpers/strokeEx1.JPG';
import strokeEx2 from '../../images/screenshots_helpers/strokeEx2.JPG';
import strokeEx3 from '../../images/screenshots_helpers/strokeEx3.jpg';
import travelEx1 from '../../images/screenshots_helpers/travelEx1.JPG';
import travelEx2 from '../../images/screenshots_helpers/travelEx2.JPG';
import travelEx3 from '../../images/screenshots_helpers/travelEx3.JPG';
import travelEx4 from '../../images/screenshots_helpers/travelEx4.JPG';
import travelEx5 from '../../images/screenshots_helpers/travelEx5.JPG';
import travelEx6 from '../../images/screenshots_helpers/travelEx6.JPG';
import travelEx7 from '../../images/screenshots_helpers/travelEx7.JPG';
import validityOptimizeEx1 from '../../images/screenshots_helpers/validityOptimizeEx1.jpg';
import validityOptimizeEx2 from '../../images/screenshots_helpers/validityOptimizeEx2.jpg';
import validityOptimizeEx3 from '../../images/screenshots_helpers/validityOptimizeEx3.jpg';
import validityOptimizeEx4 from '../../images/screenshots_helpers/validityOptimizeEx4.jpg';
import validityOptimizeEx5 from '../../images/screenshots_helpers/validityOptimizeEx5.jpg';

const imagesArray = {
  actBalEx1,
  actBalEx2,
  actBlockageEx1,
  actBlockageEx2,
  actBlockageEx3,
  actBlockageEx4,
  actEx1,
  actEx2,
  actEx3,
  actEx4,
  actEx5,
  actFlowEx1,
  actFlowEx2,
  actLeakEx1,
  actLeakEx2,
  actLeakEx3,
  actLeakEx4,
  actLeakEx5,
  actLeakEx6,
  actLeakEx7,
  airLeakEx1,
  airLeakEx2,
  airLeakEx3,
  airLeakEx4,
  airLeakEx5,
  airLeakEx6,
  actOverpressureEx1,
  actOverpressureEx2,
  actPressureEx1,
  actPressureEx2,
  actSatEx1,
  actSatEx2,
  actSatEx3,
  actSatEx4,
  actTravelEx1,
  actTravelEx2,
  actVolumeEx1,
  actVolumeEx2,
  actVolumeEx3,
  airDroopEx1,
  airDroopEx2,
  airDroopEx3,
  airDroopLengthEx1,
  airEx1,
  airEx2,
  airEx3,
  airEx4,
  airEx5,
  airEx6,
  airEx7,
  airEx8,
  airEx9,
  airActionEx1,
  airActionEx2,
  airActionEx3,
  camEx1,
  failActionEx1,
  failActionEx2,
  failActionEx3,
  airLevelsEx1,
  airLevelsEx2,
  airNoiseEx1,
  airNoiseEx2,
  airNoiseEx3,
  benchSetEx1,
  benchSetEx2,
  biasEx1,
  biasEx2,
  calibrationEx1,
  calibrationEx2,
  crossoverEx1,
  crossoverEx2,
  cutoffEx1,
  deadbandEx1,
  deadbandEx2,
  dynErrorEx1,
  frictionEx1,
  frictionEx2,
  frictionEx3,
  frictionLowEx1,
  frictionLowEx2,
  frictionLowEx3,
  frictionGraphEx2,
  frictionGraphEx3,
  frictionGraphEx4,
  frictionMedianEx1,
  frictionMedianEx2,
  frictionMedianEx3,
  frictionNoiseEx1,
  frictionNoiseEx2,
  frictionNoiseEx3,
  frictionNoiseEx4,
  frictionShapeEx1,
  frictionShapeEx2,
  frictionShapeEx3,
  frictionShapeEx4,
  frictionStaticEx1,
  frictionStaticEx2,
  frictionValEx1,
  frictionValEx2,
  frictionValEx3,
  gallingEx1,
  gallingEx2,
  gallingEx3,
  gallingEx4,
  gallingEx5,
  gallingEx6,
  gallingEx7,
  gallingEx8,
  inadequateValveEx1,
  inadequateValveEx2,
  inadequateValveEx3,
  ipEx1,
  ipEx2,
  ipEx3,
  ipEx4,
  ipEx5,
  ipEx6,
  ipTravelEx1,
  ipErrorEx1,
  ipErrorEx2,
  lagEx1,
  lagEx2,
  linearEx1,
  nomTravelEx1,
  overshootEx1,
  overshootEx2,
  overshootEx3,
  resEx1,
  resEx2,
  resEx3,
  resEx4,
  seatEntryEx1,
  seatEntryEx2,
  seatEx1,
  seatEx2,
  seatEx3,
  seatEx4,
  seatEx5,
  seatEx6,
  seatExitEx1,
  seatExitEx2,
  senEx1,
  senEx2,
  senEx3,
  senEx4,
  senEx5,
  signalSpanEx1,
  stepTimesEx1,
  stepTimesEx2,
  stepTimesEx3,
  strokeEx1,
  strokeEx2,
  strokeEx3,
  travelEx1,
  travelEx2,
  travelEx3,
  travelEx4,
  travelEx5,
  travelEx6,
  travelEx7,
  validityOptimizeEx1,
  validityOptimizeEx2,
  validityOptimizeEx3,
  validityOptimizeEx4,
  validityOptimizeEx5,
};

export default imagesArray;
