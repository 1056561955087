import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { CircularProgress, Grid, Typography, Paper, Button, IconButton, Tooltip } from '@mui/material';
import Chart from '../charts/Chart';
import InfoPopover from '../popup/InfoPopover.js';
import SingleSelect from '../filters/SingleSelect';
import DatePicker from '../filters/DatePicker';
import OptimizeProgress from '../tiles/OptimizeProgress';
import ProgressGuide from '../tiles/ProgressGuide';
import { styles } from '../styles';
import diagnosticsGraphDetails from '../../arrays/optimize/diagnosticsGraphDetails';
import {
  fetchSort,
  updateSort,
  updateFilter,
  setTablePage,
  fetchLoops,
  deleteLoops,
  fetchTest,
  fetchDevices,
  prioritizeSetActiveDevice,
  setTestStatus,
  optimizeSetActiveTest,
} from '../../actions/index';
import { connect } from 'react-redux';
import { Fullscreen, FullscreenExit } from '@mui/icons-material';
import DiagnosticGraphDetails from '../helpers/DiagnosticGraphDetails';
import PerformanceDetails from '../helpers/PerformanceDetails';
import functions from '../../functions/functions';

class ValveChartWidget extends React.Component {
  state = {
    chartHeight: null,
    gridHeight: null,
    gridWidth: this.props.initialWidth,
    zoom: false,
    dialogOpen: false,
  };
  getPercentile = (arr, perc) => {
    const mid = Math.floor(arr.length * (perc / 100)),
      nums = [...arr].sort((a, b) => a - b);
    return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
  };
  getSeriesColor() {
    return this.props.colorMode === 'dark' ? '#6fc2ff' : '#1879C0';
  }
  getSeriesCompareColor() {
    return this.props.colorMode === 'dark' ? '#ff9e8e' : '#c4462f';
  }
  getDashColor() {
    return this.props.colorMode === 'dark' ? '#ccc' : '#aaa';
  }
  getDashCompareColor() {
    return this.props.colorMode === 'dark' ? '#bbb' : '#bbb';
  }
  componentDidMount() {
    if (!!this.props.testId) {
      if (this.props.currentModule === 'Optimize' && this.props.optimize.compareTest) {
        let testId = this.getCompareTestId();
        //console.log(testId);
        //We don't have the data yet, retrieve it
        if (
          !this.props.tests_status_attribute ||
          !this.props.tests_status_attribute[testId] ||
          !(this.props.tests_status_attribute[testId] === 'loading' || this.props.tests_status_attribute[testId] === 'loaded')
        ) {
          this.props.fetchTest(testId, this.props.attribute.replace('/', ''));
          this.props.setTestStatus(this.props.attribute, testId, 'loading');
        }
      }
      const testId = this.props.testId;
      if (testId !== null) {
        if (
          !this.props.tests_status_attribute ||
          !this.props.tests_status_attribute[testId] ||
          !(this.props.tests_status_attribute[testId] === 'loading' || this.props.tests_status_attribute[testId] === 'loaded')
          // check that we aren't already loading data
        ) {
          this.props.fetchTest(testId, this.props.attribute.replace('/', ''));
          //TODO SET STATE IN RENDER ERROR
          this.props.setTestStatus(this.props.attribute, this.props.testId, 'loading');
        }
      }
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.currentModule === 'Optimize' && this.props.optimize.compareTest) {
      let testId = this.getCompareTestId();
      //console.log(testId);
      //We don't have the data yet, retrieve it
      if (
        !this.props.tests_status_attribute ||
        !this.props.tests_status_attribute[testId] ||
        !(this.props.tests_status_attribute[testId] === 'loading' || this.props.tests_status_attribute[testId] === 'loaded')
      ) {
        this.props.fetchTest(testId, this.props.attribute.replace('/', ''));
        this.props.setTestStatus(this.props.attribute, testId, 'loading');
      }
    }
    const testId = this.props.testId;
    if (!!testId) {
      //console.log(this.props.tests_status_attribute);
      //console.log(this.props.tests_status[this.props.attribute]);
      if (
        !this.props.tests_status_attribute ||
        !this.props.tests_status_attribute[testId] ||
        !(this.props.tests_status_attribute[testId] === 'loading' || this.props.tests_status_attribute[testId] === 'loaded')
        // check that we aren't already loading data
      ) {
        this.props.fetchTest(testId, this.props.attribute.replace('/', ''));
        //TODO SET STATE IN RENDER ERROR
        this.props.setTestStatus(this.props.attribute, this.props.testId, 'loading');
      }
    }
  }

  singleLoaded(attribute, testId) {
    switch (attribute) {
      case 'Profile':
      case 'Air':
      case 'Travel':
      case 'Friction':
      case 'Actuator':
      case 'I/P':
      case 'Seat':
        if (!!this.props.tests_status_attribute && !!this.props.tests_status_attribute[testId] && this.props.tests_status_attribute[testId] === 'loaded') {
          return true;
        } else {
          return false;
        }
      case 'Stroke':
      case 'Resolution':
      case 'Sensitivity':
        return true;
      /*if (
          !!this.props.tests_status['Stroke'] &&
          !!this.props.tests_status['Resolution'] &&
          !!this.props.tests_status['Sensitivity'] &&
          !!this.props.tests_status['Stroke'][testId] &&
          !!this.props.tests_status['Resolution'][testId] &&
          !!this.props.tests_status['Sensitivity'][testId] &&
          this.props.tests_status['Stroke'][testId] === 'loaded' &&
          this.props.tests_status['Resolution'][testId] === 'loaded' &&
          this.props.tests_status['Sensitivity'][testId] === 'loaded'
        ) {
          return true;
        } else {
          return false;
        }*/
      default:
        console.log('Error: fullyLoaded()');
        return false;
    }
  }
  fullyLoaded(attribute, testId) {
    return true;
    /*switch (attribute) {
      case 'Profile':
      case 'Air':
      case 'Travel':
      case 'Friction':
      case 'Actuator':
      case 'I/P':
      case 'Seat':
      /*if (
          !!this.props.tests_status['Air'] &&
          !!this.props.tests_status['Travel'] &&
          !!this.props.tests_status['Friction'] &&
          !!this.props.tests_status['Actuator'] &&
          !!this.props.tests_status['I/P'] &&
          !!this.props.tests_status['Seat'] &&
          !!this.props.tests_status['Air'][testId] &&
          !!this.props.tests_status['Travel'][testId] &&
          !!this.props.tests_status['Friction'][testId] &&
          !!this.props.tests_status['Actuator'][testId] &&
          !!this.props.tests_status['I/P'][testId] &&
          !!this.props.tests_status['Seat'][testId] &&
          this.props.tests_status['Air'][testId] === 'loaded' &&
          this.props.tests_status['Travel'][testId] === 'loaded' &&
          this.props.tests_status['Friction'][testId] === 'loaded' &&
          this.props.tests_status['Actuator'][testId] === 'loaded' &&
          this.props.tests_status['I/P'][testId] === 'loaded' &&
          this.props.tests_status['Seat'][testId] === 'loaded'
        ) {
          return true;
        } else {
          return false;
        }*/
    /*case 'Stroke':
      case 'Resolution':
      case 'Sensitivity':
        return true;
      /*if (
          !!this.props.tests_status['Stroke'] &&
          !!this.props.tests_status['Resolution'] &&
          !!this.props.tests_status['Sensitivity'] &&
          !!this.props.tests_status['Stroke'][testId] &&
          !!this.props.tests_status['Resolution'][testId] &&
          !!this.props.tests_status['Sensitivity'][testId] &&
          this.props.tests_status['Stroke'][testId] === 'loaded' &&
          this.props.tests_status['Resolution'][testId] === 'loaded' &&
          this.props.tests_status['Sensitivity'][testId] === 'loaded'
        ) {
          return true;
        } else {
          return false;
        }*/
    /*default:
        console.log('Error: fullyLoaded()');
        return false;
    }*/
  }

  processTestData(attribute, dataArr, compare = false) {
    const newData = {
      series: [],
      tooltip: {
        distance: 50,
        shared: true,
        useHTML: true,
        formatter: function (tooltip) {
          let points = !this.points ? [this.point] : this.points;
          let xSuffix = attribute === 'Seat' ? '%' : 's';
          //default implementation
          let content = `<span style="font-size: 10px">${this.x} ${xSuffix}</span></br>`;
          let ySuffix =
            attribute === 'Resolution' || attribute === 'Sensitivity' || attribute === 'Stroke'
              ? '%'
              : attribute === 'Air' || attribute === 'Actuator' || attribute === 'I/P'
              ? 'psi'
              : attribute === 'Friction'
              ? 'lbf'
              : '';
          for (let i = 0; i < points.length; i++) {
            let pointRounded = Math.round(points[i].y * 1000) / 1000;
            content += `<span style="color:${points[i].color};">●</span> ${points[i].series.name}: <b>${pointRounded} ${ySuffix}</b></br>`;
          }
          return content;
        },
      },
    };
    const defaultXAxis = {
      title: {
        enabled: true,
        text: 'Time (sec)',
      },
      labels: {
        formatter: function () {
          //convert MS to S
          var label = this.axis.defaultLabelFormatter.call(this);
          if (label.includes('k')) {
            label = label.replace('k', 's');
          } else {
            label = label + 's';
          }
          return label;
        },
      },
      events: {
        setExtremes: function (event) {
          if (!!event.max && !!event.min) {
            //Zoom has been triggered
            this.chart.update({
              yAxis: {
                originalMax: this.chart.options.yAxis[0].max,
                originalMin: this.chart.options.yAxis[0].min,
                min: null,
                max: null,
              },
            });
          } else {
            this.chart.update({
              yAxis: {
                max: this.chart.options.yAxis[0].originalMax,
                min: this.chart.options.yAxis[0].originalMin,
              },
            });
          }
        },
      },
    };
    for (let a = 0; a < dataArr.length; a++) {
      const data = dataArr[a];
      if (data.length === 0) {
      } else {
        if (attribute === 'Sensitivity') {
          let signalIncrArr = [];
          let signalDecrArr = [];
          let actualIncrArr = [];
          let actualDecrArr = [];
          let rangeIncrArr = [];
          let rangeDecrArr = [];
          for (let i = 0; i < data.length; i++) {
            signalIncrArr.push([data[i]['time'] / 100, data[i]['signalIncr']]);
            signalDecrArr.push([data[i]['time'] / 100, data[i]['signalDecr']]);
            actualIncrArr.push([data[i]['time'] / 100, data[i]['positionIncr']]);
            actualDecrArr.push([data[i]['time'] / 100, data[i]['positionDecr']]);
            //compare testing
            if (!compare) {
              rangeIncrArr.push([data[i]['time'] / 100, data[i]['positionIncr'], data[i]['signalIncr']]);
              rangeDecrArr.push([data[i]['time'] / 100, data[i]['positionDecr'], data[i]['signalDecr']]);
            } else if (a !== 0) {
              if (!!dataArr[0][i]) {
                rangeIncrArr.push([data[i]['time'] / 100, data[i]['positionIncr'], dataArr[0][i]['positionIncr']]);
                rangeDecrArr.push([data[i]['time'] / 100, data[i]['positionDecr'], dataArr[0][i]['positionDecr']]);
              }
            }
          }
          let signalName = a !== 0 ? 'Signal (Compare)' : 'Signal';
          let name = a !== 0 ? 'Sensitivity (Compare)' : 'Sensitivity';
          let color = a !== 0 ? this.getSeriesCompareColor() : this.getSeriesColor();
          let dashColor = a !== 0 ? this.getDashCompareColor() : this.getDashColor();
          let dashStyle = a !== 0 ? 'Dash' : 'ShortDash';
          let lineWidth = a !== 0 ? 1 : 2;
          let showLegend = a !== 0 ? false : true;
          newData.yAxis = {
            labels: { x: -4, format: '{text}%' },
            title: { text: 'Signal (%)', enabled: true, x: 5 },
          };
          newData.xAxis = defaultXAxis;
          newData.series.push({
            name: signalName,
            type: 'spline',
            showInLegend: showLegend,
            marker: { enabled: false },
            dashStyle: dashStyle,
            data: signalIncrArr,
            color: dashColor,
            lineWidth: lineWidth,
          });
          newData.series.push({
            name: signalName,
            type: 'spline',
            showInLegend: false,
            marker: { enabled: false },
            linkedTo: ':previous',
            dashStyle: dashStyle,
            data: signalDecrArr,
            color: dashColor,
            lineWidth: lineWidth,
          });
          newData.series.push({
            name: name,
            marker: { enabled: false },
            data: actualIncrArr,
            color: color,
            lineWidth: lineWidth,
          });
          newData.series.push({
            name: name,
            marker: { enabled: false },
            linkedTo: ':previous',
            data: actualDecrArr,
            color: color,
            lineWidth: lineWidth,
          });
          if (!compare || a !== 0) {
            newData.series.push({
              name: 'Sensitivity Error',
              enableMouseTracking: false,
              marker: { enabled: false },
              showInLegend: false,
              type: 'arearange',
              data: rangeIncrArr,
              color: this.props.colorMode === 'dark' ? 'rgb(255,189,119)' : 'rgb(201,116,25)',
              fillOpacity: this.props.colorMode === 'dark' ? 0.2 : 0.1,
              lineWidth: 0,
              zIndex: -1,
            });
            newData.series.push({
              name: 'Sensitivity Error',
              enableMouseTracking: false,
              marker: { enabled: false },
              showInLegend: false,
              linkedTo: ':previous',
              type: 'arearange',
              data: rangeDecrArr,
              color: this.props.colorMode === 'dark' ? 'rgb(255,189,119)' : 'rgb(201,116,25)',
              fillOpacity: this.props.colorMode === 'dark' ? 0.2 : 0.1,
              lineWidth: 0,
              zIndex: -1,
            });
          }
        } else if (attribute === 'Resolution') {
          let resSignalArr = [];
          let resActualArr = [];
          let resRangeArr = [];
          for (let i = 0; i < data.length; i++) {
            resSignalArr.push([data[i]['time'] / 100, data[i]['signal']]);
            resActualArr.push([data[i]['time'] / 100, data[i]['position']]);
            //compare testing
            if (!compare) {
              resRangeArr.push([data[i]['time'] / 100, data[i]['position'], data[i]['signal']]);
            } else if (a !== 0) {
              if (!!dataArr[0][i]) {
                resRangeArr.push([data[i]['time'] / 100, data[i]['position'], dataArr[0][i]['position']]);
              }
            }
          }
          let signalName = a !== 0 ? 'Signal (Compare)' : 'Signal';
          let name = a !== 0 ? 'Resolution (Compare)' : 'Resolution';
          let color = a !== 0 ? this.getSeriesCompareColor() : this.getSeriesColor();
          let dashColor = a !== 0 ? this.getDashCompareColor() : this.getDashColor();
          let dashStyle = a !== 0 ? 'Dash' : 'ShortDash';
          let lineWidth = a !== 0 ? 1 : 2;
          let showLegend = a !== 0 ? false : true;
          newData.yAxis = {
            labels: { x: -4, format: '{text}%' },
            title: { text: 'Signal (%)', enabled: true, x: 5 },
          };
          newData.xAxis = defaultXAxis;
          newData.series.push({
            name: signalName,
            type: 'spline',
            showInLegend: showLegend,
            marker: { enabled: false },
            dashStyle: dashStyle,
            data: resSignalArr,
            color: dashColor,
            lineWidth: lineWidth,
          });
          newData.series.push({
            name: name,
            marker: { enabled: false },
            data: resActualArr,
            color: color,
            lineWidth: lineWidth,
          });
          if (!compare || a !== 0) {
            newData.series.push({
              name: 'Resolution Error',
              enableMouseTracking: false,
              marker: { enabled: false },
              showInLegend: false,
              type: 'arearange',
              data: resRangeArr,
              color: this.props.colorMode === 'dark' ? 'rgb(255,189,119)' : 'rgb(201,116,25)',
              fillOpacity: this.props.colorMode === 'dark' ? 0.2 : 0.1,
              lineWidth: 0,
              zIndex: -1,
            });
          }
        } else if (attribute === 'Stroke') {
          let strSignalArr = [];
          let strActualArr = [];
          let strRangeArr = [];
          for (let i = 0; i < data.length; i++) {
            strSignalArr.push([data[i]['time'] / 100, data[i]['signal']]);
            strActualArr.push([data[i]['time'] / 100, data[i]['position']]);
            //compare testing
            if (!compare) {
              strRangeArr.push([data[i]['time'] / 100, data[i]['position'], data[i]['signal']]);
            } else if (a !== 0) {
              if (!!dataArr[0][i]) {
                strRangeArr.push([data[i]['time'] / 100, data[i]['position'], dataArr[0][i]['position']]);
              }
            }
          }
          let signalName = a !== 0 ? 'Signal (Compare)' : 'Signal';
          let name = a !== 0 ? 'Stroke (Compare)' : 'Stroke';
          let color = a !== 0 ? this.getSeriesCompareColor() : this.getSeriesColor();
          let dashColor = a !== 0 ? this.getDashCompareColor() : this.getDashColor();
          let dashStyle = a !== 0 ? 'Dash' : 'ShortDash';
          let lineWidth = a !== 0 ? 1 : 2;
          let showLegend = a !== 0 ? false : true;
          newData.yAxis = {
            labels: { x: 2, format: '{text}%' },
            title: { text: 'Signal (%)', enabled: true, x: 5 },
          };
          newData.xAxis = defaultXAxis;
          newData.series.push({
            name: signalName,
            type: 'spline',
            showInLegend: showLegend,
            marker: { enabled: false },
            dashStyle: dashStyle,
            data: strSignalArr,
            color: dashColor,
            lineWidth: lineWidth,
          });
          newData.series.push({
            name: name,
            marker: { enabled: false },
            data: strActualArr,
            color: color,
            lineWidth: lineWidth,
          });
          if (!compare || a !== 0) {
            newData.series.push({
              name: 'Stroke Error',
              enableMouseTracking: false,
              marker: { enabled: false },
              showInLegend: false,
              type: 'arearange',
              data: strRangeArr,
              color: this.props.colorMode === 'dark' ? 'rgb(255,189,119)' : 'rgb(201,116,25)',
              fillOpacity: this.props.colorMode === 'dark' ? 0.2 : 0.1,
              lineWidth: 0,
              zIndex: -1,
            });
          }
        } else if (attribute === 'Actuator') {
          let signalIncrArr = [];
          let signalDecrArr = [];
          let actualIncrArr = [];
          let actualDecrArr = [];
          let nameIncrTop = a !== 0 ? 'Incr Top (Compare)' : 'Incr Top';
          let nameDecrTop = a !== 0 ? 'Decr Top (Compare)' : 'Decr Top';
          let nameIncrBot = a !== 0 ? 'Incr Bot (Compare)' : 'Incr Bot';
          let nameDecrBot = a !== 0 ? 'Decr Bot (Compare)' : 'Decr Bot';
          let colorIncrTop = a !== 0 ? '#c4462f' : '#C866A7';
          let colorDecrTop = a !== 0 ? '#c4462f' : '#CB8A9F';
          let colorIncrBot = a !== 0 ? '#c4462f' : '#48959B';
          let colorDecrBot = a !== 0 ? '#c4462f' : '#84AFBB';
          let lineWidth = a !== 0 ? 1 : 1.5;
          let showLegend = a !== 0 ? false : true;
          for (let i = 0; i < data.length; i++) {
            signalIncrArr.push([data[i]['time'] / 100, data[i]['incrTopPressure']]);
            signalDecrArr.push([data[i]['time'] / 100, data[i]['incrBotPressure']]);
            actualIncrArr.push([data[i]['time'] / 100, data[data.length - 1 - i]['decrTopPressure']]);
            actualDecrArr.push([data[i]['time'] / 100, data[data.length - 1 - i]['decrBotPressure']]);
          }
          newData.yAxis = {
            labels: { x: -4 },
            title: { text: 'Pressure (psi)', enabled: true, x: 5 },
          };
          newData.xAxis = defaultXAxis;
          newData.series.push({
            name: nameIncrTop,
            marker: { enabled: false },
            showInLegend: showLegend,
            //dashStyle: 'Dash',
            data: signalIncrArr,
            color: colorIncrTop,
            lineWidth: lineWidth,
          });
          newData.series.push({
            name: nameIncrBot,
            marker: { enabled: false },
            showInLegend: showLegend,
            //linkedTo: ':previous',
            //dashStyle: 'Dash',
            data: signalDecrArr,
            color: colorIncrBot,
            lineWidth: lineWidth,
          });
          newData.series.push({
            name: nameDecrTop,
            marker: { enabled: false },
            showInLegend: showLegend,
            data: actualIncrArr,
            color: colorDecrTop,
            lineWidth: lineWidth,
          });
          newData.series.push({
            name: nameDecrBot,
            marker: { enabled: false },
            showInLegend: showLegend,
            //linkedTo: ':previous',
            data: actualDecrArr,
            color: colorDecrBot,
            lineWidth: lineWidth,
          });
          //TODO Fix array names
        } else if (attribute === 'Friction') {
          let signalIncrArr = [];
          let medianArr = [];
          let medianErrArr = [];
          let name = a !== 0 ? 'Friction (Compare)' : 'Friction';
          let color = a !== 0 ? '#c4462f' : '#E19878';
          let dashColor = a !== 0 ? this.getDashCompareColor() : this.getDashColor();
          let dashStyle = a !== 0 ? 'Dash' : 'ShortDash';
          let lineWidth = a !== 0 ? 1 : 1.5;
          let showLegend = a !== 0 ? false : true;
          let median = this.getPercentile(
            data.map((a) => a.friction),
            50
          );
          //let signalDecrArr = [];
          //let actualIncrArr = [];
          //let actualDecrArr = [];
          for (let i = 0; i < data.length; i++) {
            if (data[i]['frictionTime'] !== 0 && data[i]['friction'] !== 0) {
              signalIncrArr.push([data[i]['frictionTime'] / 100, data[i]['friction']]);
              medianArr.push([data[i]['frictionTime'] / 100, median]);
              medianErrArr.push([data[i]['frictionTime'] / 100, data[i]['friction'], median]);
            }

            //signalDecrArr.push([data[i]['time'], data[i]['incrBotPressure']]);
            //actualIncrArr.push([data[i]['time'], data[i]['decrTopPressure']]);
            //actualDecrArr.push([data[i]['time'], data[i]['decrBotPressure']]);
          }
          let minValue = this.getPercentile(
            data.map((a) => a.friction),
            1
          );
          newData.yAxis = {
            labels: { x: -4 },
            title: { text: 'Friction (lbf)', enabled: true, x: 5 },
            min: minValue / 2 > 0 ? minValue / 2 : 0,
          };
          newData.xAxis = defaultXAxis;
          newData.xAxis.min = 0;
          newData.xAxis.max = !data ? 1000000 : data[data.length - 1]['frictionTime'] / 100 + data[0]['frictionTime'] / 100;
          newData.series.push({
            name: name,
            marker: { enabled: false },
            showInLegend: showLegend,
            //dashStyle: 'Dash',
            data: signalIncrArr,
            color: color,
            lineWidth: lineWidth,
          });
          newData.series.push({
            name: 'Friction Median',
            showInLegend: showLegend,
            marker: { enabled: false },
            dashStyle: dashStyle,
            data: medianArr,
            color: dashColor,
            lineWidth: lineWidth,
          });
          newData.series.push({
            name: 'Friction Error',
            enableMouseTracking: false,
            marker: { enabled: false },
            showInLegend: false,
            type: 'arearange',
            data: medianErrArr,
            color: this.props.colorMode === 'dark' ? 'rgb(255,189,119)' : 'rgb(201,116,25)',
            fillOpacity: this.props.colorMode === 'dark' ? 0.2 : 0.1,
            lineWidth: 0,
            zIndex: -1,
          });
        } else if (attribute === 'I/P') {
          if (data.length === 0) {
            return null;
          }
          let signalIncrArr = [];
          let signalDecrArr = [];
          let nameIncr = a !== 0 ? 'Incr (Compare)' : 'Increasing';
          let nameDecr = a !== 0 ? 'Decr(Compare)' : 'Decreasing';
          let colorIncr = a !== 0 ? '#c4462f' : '#9473C5';
          let colorDecr = a !== 0 ? '#c4462f' : '#598FD6';
          let lineWidth = a !== 0 ? 1 : 1.5;
          let showLegend = a !== 0 ? false : true;
          //let actualIncrArr = [];
          //let actualDecrArr = [];
          for (let i = 0; i < data.length; i++) {
            signalIncrArr.push([data[i]['time'] / 100, data[i]['incrIP']]);
            signalDecrArr.push([data[i]['time'] / 100, data[data.length - 1 - i]['decrIP']]);
            //signalDecrArr.push([data[i]['time'], data[i]['incrBotPressure']]);
            //actualIncrArr.push([data[i]['time'], data[i]['decrTopPressure']]);
            //actualDecrArr.push([data[i]['time'], data[i]['decrBotPressure']]);
          }
          newData.yAxis = {
            labels: { x: -4 },
            title: { text: 'Pressure (psi)', enabled: true, x: 5 },
          };
          newData.xAxis = defaultXAxis;
          newData.series.push({
            name: nameIncr,
            marker: { enabled: false },
            showInLegend: showLegend,
            //dashStyle: 'Dash',
            data: signalIncrArr,
            lineWidth: lineWidth,
            color: colorIncr,
          });
          newData.series.push({
            name: nameDecr,
            marker: { enabled: false },
            showInLegend: showLegend,
            //dashStyle: 'Dash',
            data: signalDecrArr,
            lineWidth: lineWidth,
            color: colorDecr,
          });
        } else if (attribute === 'Travel') {
          //console.log('travel');
          let signalIncrArr = [];
          let signalDecrArr = [];
          let signalActualArr = [];
          let SignalIncrErrArr = [];
          let SignalDecrErrArr = [];
          let nameIncr = a !== 0 ? 'Incr Signal (Compare)' : 'Incr Signal';
          let nameDecr = a !== 0 ? 'Decr Signal (Compare)' : 'Decr Signal';
          let colorIncr = a !== 0 ? '#c4462f' : '#C866A7';
          let colorDecr = a !== 0 ? '#c4462f' : '#598FD6';
          let dashColor = a !== 0 ? this.getDashCompareColor() : this.getDashColor();
          let dashStyle = a !== 0 ? 'Dash' : 'ShortDash';
          let lineWidth = a !== 0 ? 1 : 1.5;
          let showLegend = a !== 0 ? false : true;
          for (let i = 0; i < data.length; i++) {
            //use Time as X, not signal
            signalIncrArr.push([data[i]['time'] / 100, data[i]['incrPosition']]);
            signalDecrArr.push([data[i]['time'] / 100, data[data.length - 1 - i]['decrPosition']]);
            SignalIncrErrArr.push([data[i]['time'] / 100, data[i]['incrPosition'], data[i]['signal']]);
            SignalDecrErrArr.push([data[i]['time'] / 100, data[data.length - 1 - i]['decrPosition'], data[i]['signal']]);
            signalActualArr.push([data[i]['time'] / 100, data[i]['signal']]);
            //signalDecrArr.push([data[i]['time'], data[i]['incrBotPressure']]);
            //actualIncrArr.push([data[i]['time'], data[i]['decrTopPressure']]);
            //actualDecrArr.push([data[i]['time'], data[i]['decrBotPressure']]);
          }
          newData.yAxis = {
            labels: {
              x: -4,
            },
            title: { text: 'Travel (%)', enabled: true, x: 5 },
            startOnTick: false,
            endOnTick: false,
          };
          newData.xAxis = defaultXAxis;
          newData.xAxis.title = { text: 'Time (sec)' /*'Control Signal (%)'*/, enabled: true, x: 5 };
          newData.xAxis.labels = {
            formatter: function () {
              var label = this.axis.defaultLabelFormatter.call(this);
              label = label + 's';
              return label;
            },
          };
          newData.tooltip.useHTML = true;
          let device = this.props.devices[this.props.optimize.activeDevice];

          let calibration = !device.calibration ? ['', ''] : device.calibration.split(' - ');
          let seatContact = calibration[0];
          let fullOpen = calibration[1];

          newData.tooltip.formatter = function (tooltip) {
            let content = `<span style="font-size: 10px">${this.x} s</span></br>`;
            for (let i = 0; i < this.points.length; i++) {
              content += `<span style="color:${this.points[i].color};">●</span> ${this.points[i].series.name}: <b>${Math.round(this.points[i].y * 100) / 100} %</b></br>`;
            }
            content += seatContact === '' && fullOpen === '' ? '' : `</br>`;
            content += seatContact === '' ? '' : `Seat Contact: <b>${seatContact} mA</b></br>`;
            content += fullOpen === '' ? '' : `Full Open: <b>${fullOpen} mA</b></br>`;
            return content;
          };
          newData.series.push({
            name: nameIncr,
            marker: { enabled: false },
            showInLegend: showLegend,
            type: 'spline',
            data: signalIncrArr,
            color: colorIncr,
            lineWidth: lineWidth,
          });
          newData.series.push({
            name: nameDecr,
            marker: { enabled: false },
            showInLegend: showLegend,
            type: 'spline',
            data: signalDecrArr,
            color: colorDecr,
            lineWidth: lineWidth,
          });
          newData.series.push({
            name: 'Signal',
            showInLegend: false,
            marker: { enabled: false },
            dashStyle: dashStyle,
            data: signalActualArr,
            color: dashColor,
            lineWidth: lineWidth,
          });
          newData.series.push({
            name: 'Travel Error',
            showInLegend: false,
            enableMouseTracking: false,
            marker: { enabled: false },
            type: 'arearange',
            data: SignalIncrErrArr,
            color: this.props.colorMode === 'dark' ? 'rgb(255,189,119)' : 'rgb(201,116,25)',
            fillOpacity: this.props.colorMode === 'dark' ? 0.2 : 0.1,
            lineWidth: 0,
            zIndex: -1,
          });
          newData.series.push({
            name: 'Signal Decr (Compare)',
            showInLegend: false,
            enableMouseTracking: false,
            marker: { enabled: false },
            type: 'arearange',
            data: SignalDecrErrArr,
            color: this.props.colorMode === 'dark' ? 'rgb(255,189,119)' : 'rgb(201,116,25)',
            fillOpacity: this.props.colorMode === 'dark' ? 0.2 : 0.1,
            lineWidth: 0,
            zIndex: -1,
          });
          //console.log(newData.tooltip);
          //console.log('t');
        } else if (attribute === 'Air') {
          let signalIncrArr = [];
          let signalDecrArr = [];
          let signalIncrErrArr = [];
          let signalDecrErrArr = [];
          let percentile85ArrIncr = [];
          let percentile85Incr = this.getPercentile(
            data.map((a) => a.incrSupplyPress),
            85
          );
          let percentile85ArrDecr = [];
          let percentile85Decr = this.getPercentile(
            data.map((a) => a.decrSupplyPress),
            85
          );
          let decArr = data.map((a) => a.decrSupplyPress);
          let incArr = data.map((b) => b.incrSupplyPress);
          let combinedArr = decArr.concat(incArr);
          let percentile85Combined = this.getPercentile(combinedArr, 85);
          let minValue = this.getPercentile(combinedArr, 0.1);
          //console.log(minValue);
          let percentile85ArrCombined = [];
          /*let percentile85Combined = this.getPercentile(
            data.map((a) => (percentile85Incr + percentile85Decr) / 2),
            50
          );*/
          let nameIncr = a !== 0 ? 'Incr Supply (Compare)' : 'Incr Supply';
          let nameDecr = a !== 0 ? 'Decr Supply (Compare)' : 'Decr Supply';
          let colorIncr = a !== 0 ? '#c4462f' : '#48959B';
          let colorDecr = a !== 0 ? '#c4462f' : '#598FD6';
          let dashColor = a !== 0 ? this.getDashCompareColor() : this.getDashColor();
          let dashStyle = a !== 0 ? 'Dash' : 'ShortDash';
          let lineWidth = a !== 0 ? 1 : 1.5;
          let showLegend = a !== 0 ? false : true;
          for (let i = 0; i < data.length; i++) {
            signalIncrArr.push([data[i]['time'] / 100, data[i]['incrSupplyPress']]);
            signalDecrArr.push([data[i]['time'] / 100, data[data.length - 1 - i]['decrSupplyPress']]);
            signalIncrErrArr.push([data[i]['time'] / 100, data[i]['incrSupplyPress'], percentile85Combined]);
            signalDecrErrArr.push([data[i]['time'] / 100, data[data.length - 1 - i]['decrSupplyPress'], percentile85Combined]);
            percentile85ArrIncr.push([data[i]['time'] / 100, percentile85Incr]);
            percentile85ArrDecr.push([data[i]['time'] / 100, percentile85Decr]);
            percentile85ArrCombined.push([data[i]['time'] / 100, percentile85Combined]);
            //percentile85
            //signalDecrArr.push([data[i]['time'], data[i]['incrBotPressure']]);
            //actualIncrArr.push([data[i]['time'], data[i]['decrTopPressure']]);
            //actualDecrArr.push([data[i]['time'], data[i]['decrBotPressure']]);
          }
          newData.yAxis = {
            labels: { x: -4 },
            title: { text: 'Pressure (psi)', enabled: true, x: 5 },
            min: minValue / 2 > 0 ? minValue / 2 : minValue < 0 ? minValue * 2 : 0,
          };
          newData.xAxis = defaultXAxis;
          newData.series.push({
            name: nameIncr,
            marker: { enabled: false },
            showInLegend: showLegend,
            data: signalIncrArr,
            color: colorIncr,
            lineWidth: lineWidth,
          });
          newData.series.push({
            name: nameDecr,
            marker: { enabled: false },
            showInLegend: showLegend,
            data: signalDecrArr,
            color: colorDecr,
            lineWidth: lineWidth,
          });
          newData.series.push({
            name: 'Air Supply 85%',
            showInLegend: false,
            marker: { enabled: false },
            dashStyle: dashStyle,
            data: percentile85ArrCombined,
            color: dashColor,
            lineWidth: lineWidth,
          });
          /*newData.series.push({
            name:
              a !== 0 ? 'Air Supply Decr 85% (Compare)' : 'Air Supply Decr 85%',
            showInLegend: false,
            marker: { enabled: false },
            dashStyle: 'Dash',
            data: percentile85ArrDecr,
            color: '#777',
            lineWidth: 1.5,
          });*/
          newData.series.push({
            name: 'Supply Error',
            showInLegend: false,
            enableMouseTracking: false,
            marker: { enabled: false },
            type: 'arearange',
            data: signalIncrErrArr,
            color: this.props.colorMode === 'dark' ? 'rgb(255,189,119)' : 'rgb(201,116,25)',
            fillOpacity: this.props.colorMode === 'dark' ? 0.2 : 0.1,
            lineWidth: 0,
            zIndex: -1,
          });
          newData.series.push({
            name: 'Air Supply Decr 85% (Compare)',
            showInLegend: false,
            enableMouseTracking: false,
            marker: { enabled: false },
            type: 'arearange',
            data: signalDecrErrArr,
            color: this.props.colorMode === 'dark' ? 'rgb(255,189,119)' : 'rgb(201,116,25)',
            fillOpacity: this.props.colorMode === 'dark' ? 0.2 : 0.1,
            lineWidth: 0,
            zIndex: -1,
          });
        } else if (attribute === 'Seat' && data.length > 0) {
          //console.log(data);
          //console.log(data[0][`positionerType`]);
          //console.log(data[0][`airAction`]);
          let positioner = data[0][`positionerType`];
          let airAction = data[0][`airAction`];
          let x1 = '';
          let y1 = '';
          let x2 = '';
          let y2 = '';
          if (positioner.toUpperCase() === 'SINGLE ACTING') {
            x1 = 'incrPosition';
            y1 = 'incrTopPressure';
            x2 = 'decrPosition';
            y2 = 'decrTopPressure';
          } else if (airAction.toLowerCase() === 'air to open' || airAction.toLowerCase() === 'ato') {
            x1 = 'incrPosition';
            y1 = 'incrBotPressure';
            x2 = 'decrPosition';
            y2 = 'decrBotPressure';
          } else if (airAction.toLowerCase() === 'air to close' || airAction.toLowerCase() === 'atc') {
            x1 = 'incrPosition';
            y1 = 'incrTopPressure';
            x2 = 'decrPosition';
            y2 = 'decrTopPressure';
          }
          let signalIncrArr = [];
          let signalDecrArr = [];
          //let actualIncrArr = [];
          //let actualDecrArr = [];
          //console.log(x1, y1, x2, y2);
          for (let i = 0; i < data.length; i++) {
            if (data[i][x1] < 10) {
              signalIncrArr.push([
                Math.round(data[i][x1] * 100) / 100,
                Math.round(data[i][y1] * 100) / 100,
                //data[i]['incrPosition'] //,
              ]);
            }
            if (data[data.length - 1 - i][x2] < 10) {
              signalDecrArr.push([
                Math.round(data[data.length - 1 - i][x2] * 100) / 100,
                Math.round(data[data.length - 1 - i][y2] * 100) / 100,
                //data[i]['decrPosition'] //,
              ]);
            }
            //signalDecrArr.push([data[i]['time'], data[i]['incrBotPressure']]);
            //actualIncrArr.push([data[i]['time'], data[i]['decrTopPressure']]);
            //actualDecrArr.push([data[i]['time'], data[i]['decrBotPressure']]);
          }
          //console.log(signalIncrArr);
          // console.log(signalDecrArr);
          //let sortedSignalIncrArr = signalIncrArr.sort((a, b) => a[0] - b[0]);
          //let sortedSignalDecrArr = signalDecrArr.sort((a, b) => a[0] - b[0]);
          //let sortedSignalIncrArr = signalIncrArr.sort((a, b) => b[1] - a[1]);
          //let sortedSignalDecrArr = signalDecrArr.sort((a, b) => b[1] - a[1]);
          //console.log(signalIncrArr);
          //console.log(signalDecrArr);
          //console.log(sortedSignalDecrArr);
          newData.plotOptions = {
            series: {
              states: {
                inactive: {
                  opacity: 0.6,
                },
              },
            },
          };
          newData.tooltip.formatter = function (tooltip) {
            let points = !this.points ? [this.point] : this.points;
            //console.log(this);
            let s = `<span style="font-size: 10px">${this.x} %</span></br>`;
            let actTime = this.x;
            for (let i = 0; i < points[0].series.chart.series.length; i++) {
              const series = points[0].series.chart.series[i];
              let diff = 9999999;
              let actPoint = null;
              for (let j = 0; j < series.data.length; j++) {
                let point = series.data[j];
                if (Math.abs(actTime - point.x) < diff) {
                  diff = Math.abs(actTime - point.x);
                  actPoint = point;
                }
              }

              if (!!actPoint) {
                s += `<span style="color:${actPoint.color};">●</span> ${actPoint.series.name}: <b>${actPoint.y} psi</b></br>`;
              }
            }

            return s;
          };
          newData.yAxis = {
            labels: { x: -4 },
            title: { text: 'Pressure (psi)', enabled: true, x: 5 },
          };
          newData.xAxis = {
            title: {
              enabled: true,
              text: '% Open',
            },
            labels: {
              formatter: function () {
                //convert MS to S
                var label = this.axis.defaultLabelFormatter.call(this);

                label = label + '%';
                return label;
              },
            },
          };
          let nameIncr = a !== 0 ? 'Incr (Compare)' : 'Increasing';
          let nameDecr = a !== 0 ? 'Decr (Compare)' : 'Decreasing';
          let colorIncr = a !== 0 ? '#c4462f' : '#48959B';
          let colorDecr = a !== 0 ? '#c4462f' : '#9473C5';
          let markerRadius = a !== 0 ? 1 : 1.5;
          let showLegend = a !== 0 ? false : true;
          newData.series.push({
            type: 'scatter',
            name: nameIncr,
            marker: {
              //enabled: false
              radius: markerRadius,
            },
            showInLegend: showLegend,
            //lineWidth: 1,
            //showInLegend: false,
            //dashStyle: 'Dash',
            data: signalIncrArr,
            color: colorIncr,
          });
          newData.series.push({
            type: 'scatter',
            name: nameDecr,
            showInLegend: showLegend,
            marker: {
              //enabled: false
              radius: markerRadius,
            },
            //lineWidth: 1,
            //showInLegend: false,
            //dashStyle: 'Dash',
            data: signalDecrArr,
            color: colorDecr,
          });
          /*newData.series.push({
            //type: 'scatter',
            name: nameIncr,
            connectNulls: true,
            gapSize: 10000,
            //marker: { enabled: false },
            //dashStyle: 'Dash',
            data: signalIncrArr,
            color: colorIncr,
          });
          newData.series.push({
            //type: 'scatter',
            name: nameDecr,
            connectNulls: true,
            gapSize: 10000,
            //marker: { enabled: false },
            //dashStyle: 'Dash',
            data: signalDecrArr,
            color: colorDecr,
          });*/
        }
      }
    }

    //console.log(newData);
    return newData;
  }

  renderFilters() {
    const { classes } = this.props;
    const testArray = ['One', 'Two', 'Test'];
    // TODO REMOVE
    /*const testFunction = e => {
      //console.log('testing functions');
      //console.log(e);
      this.props.fetchVariables('Olin1000258', 'CHL7-AI-0902-09');
    };*/
    if (this.props.filters === 'on') {
      return (
        <React.Fragment>
          <div className={classes.flex}>
            <DatePicker id='startDate' label='Start Date' name='' />
            <DatePicker id='endDate' label='End Date' name='' />
          </div>
          <div className={classes.flex}>
            <SingleSelect id='' label='Axis Min' name='' options={testArray} handleChange={this.props.fetchVariables} mountFunction={console.log('initialAction')} />
            <SingleSelect id='' label='Axis Max' name='' options={testArray} handleChange={this.props.fetchVariables} />
          </div>
          {/*<SingleSelect
            id=''
            label='Type'
            type='deviceType'
            options={['All Types'].concat(
              this.props.loops[this.props.prioritize.activeLoop].deviceTypes
            )}
            handleChange={deviceType => {
              this.props.updateFilter({ deviceType });
              //this.props.fetchVariables(this.props.prioritize.activeLoop, device);
            }}
            mountFunction={this.props.updateFilter}
            mountParams={{
              deviceType: 'All Types'
            }}
            name=''
          />*/}
          <SingleSelect
            id=''
            label='Device'
            options={this.props.loops[this.props.prioritize.activeLoop].devices}
            handleChange={(device) => {
              this.props.prioritizeSetActiveDevice(device);
              //this.props.fetchVariables(this.props.prioritize.activeLoop, device);
            }}
            mountFunction={this.props.prioritizeSetActiveDevice}
            mountParams={this.props.loops[this.props.prioritize.activeLoop].devices[0]}
            type='deviceName'
            name=''
          />
          <SingleSelect
            id=''
            label='Variables'
            type='variables'
            name=''
            options={!this.props.devices[this.props.prioritize.activeDevice] ? ['Select a variable'] : this.props.devices[this.props.prioritize.activeDevice].variables}
          />
          <Button className={classes.addVariable} variant='outlined' color='secondary'>
            Add Variable
          </Button>
        </React.Fragment>
      );
    }
  }
  renderValveHeader() {
    const { classes } = this.props;
    //console.log(this.props);
    let modifier = null;
    let label = null;
    switch (this.props.attribute) {
      case 'Resolution':
        modifier = 'resolution';
        label = 'Resolution';
        break;
      case 'Sensitivity':
        modifier = 'sensitivity';
        label = 'Sensitivity';
        break;
      case 'Stroke':
        modifier = 'stroke';
        label = 'Stroke Speed';
        break;
      default:
        break;
    }
    let status = '';
    const activeDevice = !this.props.deviceId ? this.props.optimize.activeDevice : this.props.deviceId;
    const device = this.props.devices[activeDevice];
    const capitalMod = modifier.charAt(0).toUpperCase() + modifier.slice(1);
    const activeId = device[`active${capitalMod}Id`];
    const activeIndex = !device[`${modifier}IdHistory`] || !activeId ? -1 : device[`${modifier}IdHistory`].indexOf(activeId.toString());
    const activeTarget = !device[`${modifier}AppHistory`] ? null : device[`${modifier}AppHistory`][activeIndex];
    const activeResult = !device[`${modifier}ResultHistory`] ? null : device[`${modifier}ResultHistory`][activeIndex];
    const activePrefix = !device[`${modifier}PrefixHistory`] || device[`${modifier}PrefixHistory`][activeIndex] === 'NULL' ? `` : device[`${modifier}PrefixHistory`][activeIndex];
    //const activePostfix = '';
    const activePostfix = !device[`${modifier}PostfixHistory`] || device[`${modifier}PostfixHistory`][activeIndex] === 'NULL' ? `` : device[`${modifier}PostfixHistory`][activeIndex];
    let compareDevice;
    let compareId;
    let compareIndex;
    let compareResult = '';
    if (!!this.props.optimize.compareDevice && !!this.props.optimize.compareTest && this.props.optimize.compareFlag) {
      compareDevice = this.props.devices[this.props.optimize.compareDevice];
      compareId = this.props.optimize.compareTest;
      compareIndex = !compareDevice[`${modifier}IdHistory`] || !compareDevice[`testIdHistory`] || !compareId ? 0 : compareDevice[`testIdHistory`].indexOf(compareId.toString());
      compareResult = !compareDevice[`${modifier}ResultHistory`] ? null : compareDevice[`${modifier}ResultHistory`][compareIndex];
    }
    let compareResultRounded = Math.round(compareResult * 100) / 100;
    /*console.log(activeResult, activeTarget);
    console.log(
      !device[`${modifier}AppHistory`] ? null : device[`${modifier}AppHistory`]
    );
    console.log(activeIndex);*/
    let percentage = activeResult / activeTarget;
    //this.props.devices[activeDevice][`${modifier}Result`] /
    //this.props.devices[activeDevice][`${modifier}App`];
    //console.log(percentage);
    if (percentage > 1.0) {
      status = 'Critical';
    } else if (percentage > 0.7) {
      status = 'Moderate';
    } else if (percentage > 0) {
      status = 'Okay';
    }

    /*console.log(
      modifier,
      this.props.devices[activeDevice][`${modifier}App`],
      activeResIndex === -1
        ? device.resolutionAppHistory[device.resolutionAppHistory.length - 1]
        : device.resolutionAppHistory[activeResIndex]
    );*/

    if (this.props.valveColumns === 1) {
      if (typeof this.props.devices[activeDevice][`${modifier}Result`] === 'undefined' || typeof this.props.devices[activeDevice][`${modifier}App`] === 'undefined') {
        return (
          <Typography variant='subtitle2' style={{ marginTop: 10 }}>
            <CircularProgress className={classes.loadIcon} size={15} color='secondary' />
            Loading {this.props.attribute} alignment...
          </Typography>
        );
      }
      return (
        <React.Fragment>
          <Grid container justifyContent='space-between'>
            <Grid item xs={10} className={classes.chartProgress}>
              <OptimizeProgress
                label={!label ? this.props.attribute : label}
                alignmentType={this.props.attribute}
                alignment={activeResult} //{Math.round(activeResult * 10000) / 10000}
                alignmentMax={activeTarget === null ? (modifier === 'sensitivity' ? 2.5 : 5) : activeTarget}
                alignmentShift={
                  !this.props.devices[activeDevice][`${modifier}Shift`] || this.props.devices[activeDevice][`${modifier}Shift`] === null ? 0 : this.props.devices[activeDevice][`${modifier}Shift`]
                }
                alignmentTitle={`${activePrefix !== '' && !!activePrefix ? `(${activePrefix})` : ''} ${activeResult === 'NULL' || !activeResult ? 'N/A' : Math.round(activeResult * 1000) / 1000}${
                  activeResult === 'NULL' ? '' : modifier === 'stroke' ? 's' : '%'
                } - ${status} ${activePostfix !== '' && !!activePostfix ? `(${activePostfix})` : ''}`}
                compareTitle={`${compareResult.toLowerCase() === 'null' || compareResultRounded === 0 ? '' : compareResultRounded}${
                  !compareResult || compareResult.toLowerCase() === 'null' ? '' : modifier === 'stroke' ? 's' : '%'
                }`}
                alignmentTarget={activeTarget === 'NULL' || !activeTarget ? '' : modifier === 'stroke' ? activeTarget + 's' : activeTarget + '%'}
              />
              {!activeResult ? null : (
                <ProgressGuide
                  value={Math.round(activeResult * 10000) / 10000}
                  valueMax={(activeTarget === null ? (modifier === 'sensitivity' ? 2.5 : 5) : activeTarget) * 2}
                  valueShift={
                    !this.props.devices[activeDevice][`${modifier}Shift`] || this.props.devices[activeDevice][`${modifier}Shift`] === null ? 0 : this.props.devices[activeDevice][`${modifier}Shift`]
                  }
                  //valueTarget={device.sensitivityApp}
                ></ProgressGuide>
              )}
            </Grid>
            <Grid item className={classes.chartButtonContainer} style={this.props.format ? { display: 'none' } : null}>
              <Tooltip arrow placement='top' title={!!this.state.zoom ? 'Collapse' : 'Expand'} aria-label={!!this.state.zoom ? 'Collapse' : 'Expand'}>
                <IconButton
                  className={classes.chartButton}
                  onClick={() =>
                    this.setState({
                      chartHeight: 430,
                      gridWidth: this.state.zoom ? this.props.initialWidth : 12,
                      gridHeight: this.state.zoom ? null : classes.gridHeight,
                      zoom: !this.state.zoom,
                    })
                  }
                  size='large'
                >
                  {this.state.zoom ? <FullscreenExit></FullscreenExit> : <Fullscreen></Fullscreen>}
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </React.Fragment>
      );
    } else if (this.props.valveColumns === 2) {
      return (
        <Grid container spacing={4}>
          <Grid item>
            <OptimizeProgress
              label='Resolution'
              alignment={activeResult} //{Math.round(activeResult * 10000) / 10000}
              alignmentMax={activeTarget === null ? (modifier === 'sensitivity' ? 2.5 : 5) : activeTarget}
              alignmentShift={
                !this.props.devices[activeDevice][`${modifier}Shift`] || this.props.devices[activeDevice][`${modifier}Shift`] === null ? 0 : this.props.devices[activeDevice][`${modifier}Shift`]
              }
              alignmentTitle={`${activePrefix} ${activeResult === 'NULL' || !activeResult ? 'N/A' : Math.round(activeResult * 1000) / 1000}${
                activeResult === 'NULL' ? '' : modifier === 'stroke' ? 's' : '%'
              } - ${status} ${activePostfix !== '' ? `(${activePostfix})` : ''}`}
              alignmentTarget={activeTarget === 'NULL' || !activeTarget ? '' : modifier === 'stroke' ? activeTarget + 's' : activeTarget + '%'}
            />
          </Grid>
          <Grid item>
            <OptimizeProgress
              label='Resolution'
              alignment={activeResult} //{Math.round(activeResult * 10000) / 10000}
              alignmentMax={activeTarget === null ? (modifier === 'sensitivity' ? 2.5 : 5) : this.props.devices[activeDevice][`${modifier}App`]}
              alignmentShift={
                !this.props.devices[activeDevice][`${modifier}Shift`] || this.props.devices[activeDevice][`${modifier}Shift`] === null ? 0 : this.props.devices[activeDevice][`${modifier}Shift`]
              }
              alignmentTitle={`${activePrefix} ${activeResult === 'NULL' || !activeResult ? 'N/A ' : Math.round(activeResult * 1000) / 1000}${
                activeResult === 'NULL' ? '' : modifier === 'stroke' ? 's' : '%'
              } - ${status} ${activePostfix !== '' ? `(${activePostfix})` : ''}`}
              alignmentTarget={activeTarget === 'NULL' || !activeTarget ? '' : modifier === 'stroke' ? activeTarget + 's' : activeTarget + '%'}
            />
          </Grid>
        </Grid>
      );
    }
  }
  getCompareTestId() {
    let compareTest = this.props.optimize.compareTest;
    let compareDevice = this.props.optimize.compareDevice;
    let device = this.props.devices[compareDevice];
    let testIndex = device.testIdHistory.indexOf(compareTest.toString());
    let testId = '';
    switch (this.props.attribute) {
      case 'Profile':
      case 'Air':
      case 'Travel':
      case 'Friction':
      case 'Actuator':
      case 'I/P':
      case 'Seat':
        testId = device.profileIdHistory[testIndex];
        break;
      case 'Stroke':
        testId = device.strokeIdHistory[testIndex];
        break;
      case 'Resolution':
        testId = device.resolutionIdHistory[testIndex];
        break;
      case 'Sensitivity':
        testId = device.sensitivityIdHistory[testIndex];
        break;
      case 'Alignment':
        testId = device.testIdHistory[testIndex];
        break;
      default:
        console.log(`Error: ${this.props.attribute}`);
    }
    return testId;
  }
  render() {
    //console.log(this.props);
    //console.log(this.props.tests_attribute_id);
    //console.log(this.props.tests_attribute);
    //console.log(this.props.attribute);
    //If Compare is used, check if we have the data for comparison
    /* if (
      this.props.currentModule === 'Optimize' &&
      this.props.optimize.compareTest
    ) {
      let testId = this.getCompareTestId();
      //console.log(testId);
      //We don't have the data yet, retrieve it
      if (
        !this.props.tests_status[this.props.attribute] ||
        !this.props.tests_status[this.props.attribute][testId] ||
        !(
          this.props.tests_status[this.props.attribute][testId] === 'loading' ||
          this.props.tests_status[this.props.attribute][testId] === 'loaded'
        )
      ) {
        this.props.fetchTest(testId, this.props.attribute.replace('/', ''));
        this.props.setTestStatus(this.props.attribute, testId, 'loading');
      }
    }*/
    const { classes } = this.props;
    let attribute = this.props.attribute.toLowerCase();
    const testId = this.props.testId;
    //console.log(this.props);
    let newData = { series: [] };
    let dataArr = [];
    if (testId !== null) {
      if (
        !!this.props.tests_attribute &&
        !!this.props.tests_attribute[testId] &&
        !!this.props.tests_attribute[testId].data &&
        (this.fullyLoaded(this.props.attribute, testId) || (this.props.loadingValidation === 'single' && this.singleLoaded(this.props.attribute, testId)))
      ) {
        const data = this.props.tests_attribute[testId].data;
        //console.log(data);
        dataArr = [data];

        //console.log(this.props);
        //console.log(this.props.devices[this.props.activeDevice]);

        // console.log(newData.tooltip);
        //console.log(newData);
      }
    }
    if (this.props.currentModule === 'Optimize' && this.props.optimize.compareTest) {
      let compareTestId = this.getCompareTestId();
      //console.log(testId);
      //We don't have the data yet, retrieve it

      if (!!this.props.tests_status_attribute && !!this.props.tests_status_attribute[compareTestId] && this.props.tests_status_attribute[compareTestId] === 'loaded') {
        //data = [data, this.props.tests_attribute[compareTestId].data];
        //add compare series to the array of data
        if (dataArr.length === 0) {
          dataArr.push([]);
        }
        dataArr.push(this.props.tests_attribute[compareTestId].data);
      }
    }
    const printStyles = functions.setPrintStyles(this.props.activeTab);
    newData = this.processTestData(this.props.attribute, dataArr, this.props.optimize.compareFlag);
    //console.log(newData);
    //TODO FIX TEST VS TESTSTATUS CASE DISCREPANCY
    //NOTE: test_status uses title case and tests users lowercase
    /*console.log(newData.series.length === 0);
    console.log(newData);*/
    if (
      /* ((newData === null || newData.series.length === 0) && testId === null) ||
      // !this.props.tests[attribute] ||
      ((newData === null || newData.series.length === 0) &&
        !!this.props.tests[attribute] &&
        !!this.props.tests[attribute][testId] &&
        this.props.tests[attribute][testId].data.length === 0 &&
        this.props.tests_status[this.props.attribute][testId] === 'loaded')*/
      false
    ) {
      //todo put in full correction when no data is found
      /*
      //console.log(`No data available to graph for ${this.props.attribute}`);
      console.log(testId === null);
      console.log(!this.props.tests[attribute]);
      //console.log(newData === null);

      console.log(
        (newData === null || newData.series.length === 0) &&
          !!this.props.tests[attribute] &&
          !!this.props.tests[attribute][testId] &&
          this.props.tests_status[this.props.attribute][testId] === 'loaded'
      );*/
      /*if (
        !!this.props.tests[attribute] &&
        this.props.tests_status[this.props.attribute][testId]
      ) {
        console.log(this.props.tests_status[this.props.attribute][testId]);
      }*/
      return (
        <Grid item xs={12} sm={12} md={!this.state.gridWidth ? 6 : this.state.gridWidth} className={classes.widgetSpacing}>
          <Paper className={printStyles ? classes.printPaper : classes.paper} elevation={3}>
            <Grid container justifyContent='space-between'>
              <Grid item>
                <Grid container>
                  {printStyles ? null : (
                    <Grid item style={{ marginTop: -4 }}>
                      <InfoPopover
                        resize
                        title={this.props.attribute}
                        content={
                          this.props.attribute === 'Resolution' || this.props.attribute === 'Sensitivity' || this.props.attribute === 'Stroke' ? (
                            <PerformanceDetails id={this.props.attribute} />
                          ) : (
                            <DiagnosticGraphDetails id={this.props.attribute} />
                          )
                        }
                      />
                    </Grid>
                  )}
                  <Grid item>
                    <Typography variant='subtitle2' color='secondary' className={printStyles ? classes.printSubtitle : null}>
                      {this.props.attribute === 'Travel' ? 'Travel and Positioner' : this.props.attribute === 'Air' ? 'Air Supply' : this.props.attribute}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Typography variant='body2'>{`No Data Found for ${this.props.attribute}`}</Typography>
          </Paper>
        </Grid>
      );
    }
    if (this.props.optimize.compareFlag) {
      let compareTestId = this.getCompareTestId();
      if (!(!this.props.tests_status_attribute || (!!this.props.tests_status_attribute[compareTestId] && this.props.tests_status_attribute[compareTestId] === 'loaded'))) {
        return (
          <Grid item xs={12} sm={12} md={this.state.gridWidth} className={classes.widgetSpacing}>
            <Paper className={printStyles ? classes.printPaper : classes.paper} elevation={3}>
              <Typography variant='subtitle2'>
                <CircularProgress className={classes.loadIcon} size={15} color='secondary' />
                {`Loading New Data Found for ${this.props.attribute}`}
              </Typography>
            </Paper>
          </Grid>
        );
      }
    }
    return (
      <Grid
        item
        xs={12}
        sm={!this.props.printStyles ? this.state.gridWidth : 12}
        md={!this.props.printStyles ? this.state.gridWidth : 12}
        lg={this.props.initialWidth === 12 ? 12 : this.state.gridWidth}
        className={classes.widgetSpacing}
      >
        <Paper
          className={`${!this.props.embed ? null : classes.embedPaper} ${printStyles ? classes.printPaper : classes.paper} ${classes.avoidBreak}`}
          elevation={!this.props.embed ? 3 : 0}
          style={this.props.format && this.props.comparator ? { height: 330 } : null}
        >
          {
            //TODO add corrected header
            !this.props.progress && this.props.attribute !== 'Resolution' && this.props.attribute !== 'Sensitivity' && this.props.attribute !== 'Stroke' ? (
              <Grid container justifyContent='space-between'>
                <Grid item>
                  <Grid container>
                    {printStyles ? null : (
                      <Grid item style={{ marginTop: -4 }}>
                        <InfoPopover
                          resize
                          title={diagnosticsGraphDetails[this.props.attribute].title}
                          titleIcon={diagnosticsGraphDetails[this.props.attribute].title}
                          id={this.props.attribute}
                          useOptimizeGraphTabs
                          type='diagnosticsGraph'
                        />
                      </Grid>
                    )}
                    <Grid item>
                      <Typography variant='subtitle2' color='secondary' className={printStyles ? classes.printTitle : null}>
                        {this.props.attribute === 'Travel' ? 'Travel and Positioner' : this.props.attribute === 'Air' ? 'Air Supply' : this.props.attribute}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item className={classes.chartButtonContainer}>
                  <Tooltip arrow placement='top' title={!!this.state.zoom ? 'Collapse' : 'Expand'} aria-label={!!this.state.zoom ? 'Collapse' : 'Expand'}>
                    <IconButton
                      className={classes.chartButton}
                      onClick={() =>
                        this.setState({
                          chartHeight: 430,
                          gridWidth: this.state.zoom ? 6 : 12,
                          gridHeight: this.state.zoom ? null : classes.gridHeight,
                          zoom: !this.state.zoom,
                        })
                      }
                      size='large'
                    >
                      {this.state.zoom ? <FullscreenExit></FullscreenExit> : <Fullscreen></Fullscreen>}
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            ) : (
              this.renderValveHeader()
            )
          }

          {testId !== null && (!this.props.tests_attribute || (!this.props.tests_attribute[testId] && (newData === null || newData.series.length === 0))) ? (
            <Typography variant='subtitle2' style={{ marginTop: 10 }}>
              <CircularProgress className={classes.loadIcon} size={15} color='secondary' />
              Loading {this.props.attribute} Chart...
            </Typography>
          ) : testId === null ||
            ((newData === null || newData.series.length === 0) &&
              !!this.props.tests_attribute &&
              !!this.props.tests_attribute[testId] &&
              this.props.tests_attribute[testId].data.length === 0 &&
              this.props.tests_status_attribute[testId] === 'loaded') ? (
            <Typography variant='subtitle2' style={{ margin: 8 }}>
              {`No Data Found for ${this.props.attribute}.`}
            </Typography>
          ) : (
            <div className={classes.chart}>
              <Chart attribute={attribute} type={this.props.type} options={newData} chartHeight={this.state.zoom ? this.state.chartHeight : null} embed={this.props.embed} />
            </div>
          )}
        </Paper>
      </Grid>
    );
  }
}

ValveChartWidget.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  //console.log(state.tests.tests);
  //console.log(ownProps.attribute);
  //console.log(state.tests.tests[ownProps.attribute]);
  //console.log(ownProps);
  return {
    sort: state.sort,
    table: state.table,
    loops: state.loops.loops,
    prioritize: state.prioritize,
    //tests: state.tests.tests,
    tests_attribute: state.tests.tests[ownProps.attribute.toLowerCase()],
    /*tests_attribute_id: !state.tests.tests[ownProps.attribute.toLowerCase()]
      ? {}
      : state.tests.tests[ownProps.attribute.toLowerCase()][ownProps.testId],*/
    //tests_status: state.tests.status,
    tests_status_attribute: state.tests.status[ownProps.attribute],
    /*tests_status_attribute_id: !state.tests_status[ownProps.attribute]
      ? null
      : state.tests.status[ownProps.attribute][ownProps.testId],*/
    optimize: state.optimize,
    devices: state.devices.devices,
    currentModule: state.currentModule.currentModule,
    print: state.print.print,
    format: state.format.format,
    colorMode: state.colorMode.colorMode,
    activeTab: state.optimize.activeTab,
  };
};

//export default withStyles(styles)(ValveChartWidget);
export default connect(mapStateToProps, {
  fetchSort,
  updateSort,
  updateFilter,
  setTablePage,
  fetchLoops,
  deleteLoops,
  //fetchVariables,
  fetchTest,
  fetchDevices,
  //fetchDeviceCompareTests,
  prioritizeSetActiveDevice,
  setTestStatus,
  optimizeSetActiveTest,
})(withStyles(styles)(ValveChartWidget));
